import React, { memo } from 'react';
import Modal from '../../../../ui/Modal';
import PresidiumRfls from './PresidiumRfls';
import { presidiumsList } from './config';
import './index.scss';


const PresidiumModal = ({ alias, onClose }) => (
    <Modal
        className="presidium-modal"
        iconName="icon-presidium-white"
        headerName={alias === 'rfls' ? 'Президиум РФЛС' : 'Президиум'}
        showModal={!!alias}
        handleClose={onClose}
        noBackdrop
    >
        {alias &&
            <div className="presidium-modal__content">
                {alias === 'rfls' ?
                    <PresidiumRfls/> :
                    <ol className="presidium-modal__list">
                        {presidiumsList[alias].members.map((member, i) =>
                            <li className="presidium-modal__list-item" key={i}>{member}</li>
                        )}
                    </ol>
                }
            </div>
        }
    </Modal>
);

export default memo(PresidiumModal);