import React, { memo } from 'react';
import './index.scss';


const Loading = ({ centered = true, inline = false }) => (
    <div className={`Loading${inline ? ' inline' : centered ? ' centered' : ''}`}>
        <div className="Loading__title" />
    </div>
);

export default memo(Loading);