import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../../../Avatar';
import { widgetLoginIcon } from '../../../../../appConfig';
import { getInitials } from '../../../../../utils/getInitials';
import { judgeIcon } from '../../../UserLayout/config';
import { connectAuthUserInfo } from '../../../../../pages/Login/connectors';
import { IconIdentifiedUser } from '../../../../../ui/Icons';
import { useConfirm } from '../../../../../shared/confirm.hook';
import MenuLinks from './MenuLinks';


const Content = ({
    accountType,
    roles,
    alias,
    isMobile1080,
    is_active_profile,
    logOutUser,
    loginUserSuccess,
    logo,
    menuBackground,
    name,
    open,
    setOpen,
    setShowModal,
    userType,
    multi_accounts,
    setIsShowLoginAlert,
    changeActiveUser,
    handleLogoutOneUser,
    history,
    isAcceptPersonalData,
    userName,
}) => {
    const { confirm } = useConfirm();
    
    const getInitialName = user_info => {
        let name = user_info.name;

        let firstName = '';
        let lastName = '';

        if (!!name) name = name.replace(/["-'.)(]/g, '').replace(/\s+/g, ' ').trim();

        if (!name && user_info) {
            firstName = user_info.first_name ? user_info.first_name : user_info.name.split(' ')[0].replace(/["']/g, '');
            lastName = user_info.last_name ? user_info.last_name : user_info.name.split(' ')[1];
        } else if (name && name.split(' ').length > 1) {
            firstName = name.split(' ')[0];
            lastName = name.split(' ')[1];
        } else {
            firstName = name?.split('')[0];
            lastName = name?.split('')[1];
        }

        return (name || user_info) ? firstName[0] + lastName[0] : '';
    };
    
    return (
        <div className="widget-login__content">
            <div className="widget-login__userpic-wrap">
                <div className="widget-login__bg-box">
                    {menuBackground ?
                        <img src={menuBackground} alt=""/> :
                        <img src="/static/images/widget-login/userpic-bg.jpg" alt=""/>
                    }
                </div>
                <Avatar
                    data="logo"
                    card="widget-login"
                    logo={logo}
                    open={open}
                    userType={userType}
                    name={name}
                    subclass="userpic"
                />
            </div>
            <div className="widget-login__username">
                {(userType === 1 || userType === 101) &&
                    <Link to={`/user/${alias}`}>{name ? getInitials(name) : 'Аноним'}
                        {!!roles?.open_roles?.length && roles.open_roles.some(item => item.key_name === 'role_judge') ?
                            <span className="widget-login__judge-icon">
                                {judgeIcon}
                            </span>
                            :
                            <span className="widget-login__judge-icon">
                                <IconIdentifiedUser fill={isAcceptPersonalData ? '#54A7FF' : '#B9B9B9'}/>
                            </span>
                        }
                    </Link>
                }
                {userType === 3  && (alias !== 'rkf' || alias === 'rkf-online') &&
                    <Link to={is_active_profile ? `/club/${alias}` : '/not-confirmed'}>{name}</Link>
                }
                {(userType === 5 || alias === 'rkf') &&
                    <Link to={is_active_profile ? `/${alias}` : '/not-confirmed'}>{name}</Link>
                }
                {userType === 4 &&
                    <>
                        <Link to={is_active_profile ? `/kennel/${alias}` : '/kennel/activation'}>
                            {name}
                            <span className="widget-login__judge-icon">
                                <IconIdentifiedUser fill={isAcceptPersonalData ? '#54A7FF' : '#B9B9B9'}/>
                            </span>
                        </Link>
                    </>
                }
                {userType === 7 &&
                    <Link to={is_active_profile ? `/nbc/${alias}` : '/not-confirmed'}>{name}</Link>
                }
            </div>
            <ul className="widget-login__list">
                {is_active_profile &&
                    <MenuLinks
                        alias={alias}
                        setOpen={setOpen}
                        accountType={accountType}
                        setShowModal={setShowModal}
                        loginUserSuccess={loginUserSuccess}
                        is_active_profile={is_active_profile}
                        userTypes={
                            userType === 1 ? 'user' :
                                userType === 3 && (alias !== 'rkf' || alias === 'rkf-online') ? 'club' :
                                    userType === 5 || alias === 'rkf' ? 'federation' :
                                        userType === 4 ? 'kennel' :
                                            userType === 7 ? 'nbc' :
                                                userType === 101 && 'admin'
                        }
                        logInLogOut={
                            (accountType === 5 || accountType === 101) &&
                                (userType === 5 || userType === 101 || alias === 'rkf') ? 'in' :
                                (accountType === 5 || accountType === 101) &&
                                    (userType !== 5 && userType !== 101) && alias !== 'rkf' && 'out'
                        }
                        handleLogoutOneUser={handleLogoutOneUser}
                    />
                }
                <li className="widget-login__item widget-login__item--logout" onClick={() => setOpen(false)}>
                    <button
                        onClick={async () => {
                            if (multi_accounts.length > 1) {
                                if (await confirm('Уважаемый пользователь!', `Желаете выйти из аккаунта ${userName}?`)) {
                                    handleLogoutOneUser();
                                }
                            } else {
                                logOutUser();
                                history.push('/');
                            }
                        }}
                    >
                        {widgetLoginIcon.exit}Выход
                    </button>
                </li>
                {!!multi_accounts?.length && multi_accounts.map(account =>
                    !account.is_active_account &&
                    <li
                        className="widget-login__item widget-login__additional-account"
                        key={account.user_info.id}
                    >
                        <div className="widget-login__additional-account-info">
                            <Link
                                to={account.user_info.user_full_alias}
                                onClick={() => changeActiveUser(account.access_token)}
                            >
                                {account.user_info.logo_link ?
                                    <img
                                        className="widget-login__additional-account-logo
                                            widget-login__additional-account-logo_img"
                                        src={account.user_info.logo_link}
                                        alt={account.user_info.name}
                                    />
                                    :
                                    <span
                                        className="widget-login__additional-account-logo
                                            widget-login__additional-account-logo_initials"
                                    >
                                        {getInitialName(account.user_info).toUpperCase()}
                                    </span>
                                }

                                <span className="widget-login__additional-account-name">
                                    {account.user_info.name}
                                </span>
                            </Link>
                        </div>
                    </li>
                )}
                {(!multi_accounts || !multi_accounts.length || multi_accounts.length < 5) &&
                    <li className="widget-login__item widget-login__add-user">
                        <button onClick={() => {
                            setIsShowLoginAlert(true);
                        }}>
                            Добавить пользователя
                        </button>
                    </li>
                }
                {!isMobile1080 &&
                    <li className="widget-login__item" onClick={() => setOpen(false)}>
                        <a className="widget-login__item-link"
                            href="https://info.rkf.online/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {widgetLoginIcon.knowledgeBase}
                            База знаний
                        </a>
                    </li>
                }
            </ul>
        </div>
    );
};

export default memo(connectAuthUserInfo(Content));
