import React, { memo } from 'react';
import { Dialog, DialogContent, DialogActions, AppBar, Toolbar, Zoom } from '@material-ui/core';
import Button from '../Buttons/Button';
import './index.scss';


const CustomConfirm = ({ isOpen, title, confirmation, onSuccess, onCancel }) => (
    <Dialog
        className="custom-confirm"
        disableEscapeKeyDown
        TransitionComponent={Zoom}
        open={isOpen}
    >
        {title &&
            <AppBar position="static" className="custom-confirm__top">
                <Toolbar className="custom-confirm__title">
                    {title}
                </Toolbar>
            </AppBar>
        }
        <DialogContent className="custom-confirm__content">
            {confirmation}
        </DialogContent>
        <DialogActions className="custom-confirm__controls">
            <Button primary onClick={onSuccess}>Ок</Button>
            <Button secondary onClick={onCancel}>Отмена</Button>
        </DialogActions>
    </Dialog>
);

export default memo(CustomConfirm);