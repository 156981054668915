export const selectIsAuthenticated = state => {
    const {
        isAuthenticated,
        profile_id,
        is_active_profile,
        user_info,
        access_token,
        multi_accounts,
    } = state.authentication;
    if (isAuthenticated) {
        const { user_type, alias } = user_info;
        return {
            isAuthenticated,
            profile_id,
            is_active_profile,
            user_type,
            alias,
            access_token,
            multi_accounts,
        };
    }
    return { isAuthenticated, profile_id, is_active_profile };
};

export const selectWidgetLogin = state => {
    const {
        isAuthenticated,
        is_active_profile,
        account_type,
        user_info,
        access_token,
        helpdesk_api_key,
        multi_accounts,
    } = state.authentication;

    if (isAuthenticated) {
        return {
            isAuthenticated,
            is_active_profile,
            account_type,
            access_token,
            helpdesk_api_key,
            user_info,
            multi_accounts,
        };
    }

    return { isAuthenticated, multi_accounts };
};

export const selectUserInfo = state => {
    const {
        isAuthenticated,
        user_info,
        roles,
        profile_id,
        is_active_profile,
        access_token,
        helpdesk_api_key,
        banned,
        multi_accounts,
    } = state.authentication;

    if (isAuthenticated) {
        return {
            isAuthenticated,
            access_token,
            helpdesk_api_key,
            user_info,
            roles,
            profile_id,
            is_active_profile,
            banned,
            multi_accounts,
        };
    }

    return { isAuthenticated, multi_accounts };
};