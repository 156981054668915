import React, { memo, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import Button from '../Buttons/Button';
import { blockContent } from '../../utils/blockContent';
import { IconCross } from '../Icons';
import './index.scss';


const Alert = ({
    title,
    text,
    autoclose,
    okButton,
    onOk,
}) => {
    const [timerId, setTimerId] = useState(null);
    const [showModal, setShowModal] = useState(true);
    const ref = useRef(null);

    useEffect(() => {
        !timerId && !!autoclose && onOk && timer(okClick, 500 + autoclose * 1000);
    });

    useEffect(() => {
        if (showModal) {
            blockContent(true);
        } else {
            blockContent(false);
        }
    }, [showModal]);

    const timer = (func, time) => {
        if (timerId !== null) {
            clearTimeout(timerId);
        } 
        setTimerId(setTimeout(func, time));
    };

    const okClick = () => {
        if (ref.current) {
            ref.current.classList.add('exit-alert');
        }
        
        if (onOk) {
            timer(onOk, 500);
        } 
        
        setShowModal(false);
    };
    
    return createPortal((
        <div className="alert__wrap">
            <div className="alert" ref={ref}>
                <div className="alert__title">{title}</div>
                <div className="alert__text">{text}</div>
                {okButton && (
                    <div className="alert__button">
                        <Button primary onClick={okClick}>OK</Button>
                    </div>
                )}
                <div className="alert__cross" onClick={okClick}>
                    <IconCross/>
                </div>
            </div>
        </div>
    ), document.body);};

export default memo(Alert);