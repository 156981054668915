import React from 'react';
import { connect } from 'formik';
import ImageInput from '../../../ui/FormComponents/ImageInput';


const FormikInputImage = ({ formik, id, className, name, placeholder, disabled }) => (
    <ImageInput
        id={id}
        className={className}
        name={name}
        placeholder={placeholder}
        disabled={disabled}
        onChange={file => formik.setFieldValue(name, file)}
        onDrop={file => formik.setFieldValue(name, file)}
        onBlur={formik.onBlur}
    />
);

export default connect(FormikInputImage);