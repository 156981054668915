import React from 'react';
import Select from 'react-select';
import { connect, getIn } from 'formik';
import { defaultReactSelectStyles } from './config';

export const NoOptionsMessage = () => {
    return ('Нет опций для выбора');
};

const FormikReactSelect = ({
    formik,
    options,
    id,
    name,
    className,
    placeholder,
    disabled,
    clearable,
    defaultValue,
    isMulti,
    onChange,
    closeMenuOnSelect,
    components,
    onKeyDown,
    onInput,
    noOptionsMessage,
    ...other
}) => {
    const getValue = () => {
        const value = getIn(formik.values, name);

        if (value && options) {
            return isMulti ?
                options.filter(option => value.indexOf(option.value) >= 0) :
                options.find(option => option.value === value);
        } else {
            return isMulti ? [] : '';
        }
    };

    const handleChange = value => {
        formik.setFieldValue(name, value.value);
        onChange && onChange(value);
    };

    return (
        <Select
            components={{ ...components, NoOptionsMessage }}
            id={id}
            isMulti={isMulti}
            closeMenuOnSelect={closeMenuOnSelect}
            styles={defaultReactSelectStyles.defaultTheme}
            className={className}
            name={name}
            value={getValue()}
            options={options}
            placeholder={placeholder}
            isDisabled={disabled}
            onChange={handleChange}
            onBlur={formik.handleBlur}
            onKeyDown={onKeyDown}
            onInputChange={onInput}
            clearable={clearable}
            defaultValue={defaultValue}
            isSearchable
            classNamePrefix="RSInput"
            noOptionsMessage={noOptionsMessage}
            {...other}
        />
    );
};

export default connect(FormikReactSelect);