import React, { memo, useState, useEffect } from 'react';
import { NavLink, Link, withRouter, useLocation, useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { connectShowFilters } from '../connectors';
import WidgetLogin from '../Header/components/WidgetLogin';
import AuthButtons from '../Header/components/AuthButtons';
import ZlineWidget from '../../ZLineWidget';
import { footerNav } from '../../../appConfig';
import MenuComponent from '../MenuComponent';
import { connectAuthVisible } from '../../../pages/Authorization/connectors';
import { blockContent } from '../../../utils/blockContent';
import './footerMenu.scss';


const FooterMenu = ({ isAuthenticated, setShowFilters }) => {
    const [open, setOpen] = useState(false);
    const [showZlineModal, setShowZlineModal] = useState(false);
    const [isPageWithMenu, setIsPageWithMenu] = useState(false);

    const { login_page } = useSelector(state => state.layout) || {};
    const location = useLocation();
    const isExhibitionPage = !!useRouteMatch('/exhibitions/:id');

    useEffect(() => {
        const pathArray = location.pathname.replace('/', '').split('/');

        if (
            pathArray.includes('club') ||
            pathArray.includes('kennel') ||
            pathArray.includes('nbc') ||
            pathArray.includes('user') ||
            pathArray.includes('rkf') ||
            pathArray.includes('rfls') ||
            pathArray.includes('rfss') ||
            pathArray.includes('oankoo') ||
            pathArray.includes('rfos') ||
            isExhibitionPage
        ) {
            setIsPageWithMenu(true);
        } else {
            setIsPageWithMenu(false);
        }
    }, [location]);

    useEffect(() => {
        if (showZlineModal || open ) {
            blockContent(true);
        } else {
            blockContent(false);
        }
    }, [showZlineModal, open]);

    const hideSideMenu = () => {
        setShowFilters({ isOpenFilters: false, isOpen: false });
    };

    const hideWidgetLoginPopup = () => {
        setOpen(false);
    };

    const handleZlineClick = e => {
        e.preventDefault();
        hideWidgetLoginPopup();
        setShowZlineModal(true);
    };

    return (
        <>
            <div className={`footer__menu${!isAuthenticated ? ' unregistered-user' : ''}`}
                onClick={hideSideMenu}
            >
                <NavLink to="/" className="footer__menu-link class-for-grid-block1" onClick={hideWidgetLoginPopup}>
                    {footerNav[0].image}
                    <span>{footerNav[0].title}</span>
                </NavLink>
                <Link to="/" className="footer__menu-link class-for-grid-block2" onClick={ e => handleZlineClick(e) }>
                    { footerNav[5].image }
                    <span>{ footerNav[5].title }</span>
                </Link>
                {isAuthenticated &&
                    <WidgetLogin footerNav={footerNav[2]} setOpen={setOpen} open={open}/>
                }
                {!isAuthenticated &&
                    <AuthButtons
                        className="footer__menu-link"
                        login_page={login_page}
                        isRegistrationHidden={isPageWithMenu}
                    />
                }
                {(isAuthenticated || isPageWithMenu) &&
                    <div className="class-for-grid4"
                        onClick={hideWidgetLoginPopup}
                    >
                        <MenuComponent/>
                    </div>
                }
            </div>
            <ZlineWidget
                isModalShow={showZlineModal}
                handleClose={() => setShowZlineModal(false)}
                iframeLink={process.env.NODE_ENV === 'production' ?
                    'https://zline.me/widgets/registration-for-service?id=33' :
                    'https://zsstage.uep24.ru/widgets/registration-for-service?id=92'
                }
            />
        </>
    );
};

export default withRouter(connectAuthVisible(connectShowFilters(memo(FooterMenu))));
