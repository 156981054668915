import React, { memo, useState, useEffect, useContext, useRef } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import InfiniteScroll from 'react-infinite-scroll-component';
import OutsideClickHandler from 'react-outside-click-handler';
import ls from 'local-storage';
import Loading from '../../../../../ui/Loading';
import PopupModal from '../../../../../ui/PopupModal';
import { blockContent } from '../../../../../utils/blockContent';
import { Request } from '../../../../../utils/request';
import { NotificationsContext } from '../../../../../app/context';
import { connectWidgetLogin } from '../../../../../pages/Login/connectors';
import { DEFAULT_IMG } from '../../../../../appConfig';
import useIsMobile from '../../../../../utils/useIsMobile';
import NotificationItem from './NotificationItem';
import NotificationCategories from './NotificationCategories';
import './styles.scss';


const defaultCategories = [
    {
        id: 2,
        name: 'Новые',
        icon: '/static/new-icons/notifications/new.svg',
    },
    {
        id: 3,
        name: 'Важные',
        icon: '/static/new-icons/notifications/required.svg',
    },
    {
        id: 4,
        name: 'Заявки',
        icon: '/static/new-icons/notifications/applications.svg',
    },
];

const Notifications = ({ isAuthenticated }) => {
    const [loaded, setLoaded] = useState(false);
    const [open, setOpen] = useState(false);
    const [notificationsList, setNotificationsList] = useState({});
    const [notificationsListNew, setNotificationsListNew] = useState([]);
    const [notificationsListMust, setNotificationsListMust] = useState([]);
    const [notificationsListRequest, setNotificationsListRequest] = useState([]);
    const [showDot, setShowDot] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(2);
    const [categories, setCategories] = useState(defaultCategories);
    const [startElement, setStartElement] = useState(1);

    const isMobile1080 = useIsMobile(1080);

    const { notification } = useContext(NotificationsContext);
    const alias = ls.get('user_info') ? ls.get('user_info')?.alias : '';
    const user_type = ls.get('user_info')?.user_type;

    const notificationsRef = useRef();

    useEffect(() => {
        if (notification?.value?.length) {
            setShowDot(true);
            getNotifications(currentCategory, startElement);
            getNotificationsCounters();
        } else {
            setShowDot(!!notification?.hasNewMessage);
        }

        if (loaded && notification.value) {
            const updated = [...notificationsListNew];
            updated?.length > 11 && updated.pop();
            updated.unshift(JSON.parse(notification.value));
            setNotificationsListNew(updated);
        }
    }, [notification]);

    useEffect(() => {
        if (open) {
            blockContent(true);
        } else {
            blockContent(false);
        }
    }, [open]);

    useEffect(() => {
        const hasMore = (notifications) => {
            return  !(!notifications || notifications.length < 10 ||
                (notifications.length && notifications.length % 10 < 10 && notifications.length % 10 !== 0));
        };

        if (notificationsListNew || notificationsListMust || notificationsListRequest) {
            setNotificationsList({
                new: {
                    notifications: notificationsListNew,
                    hasMore: hasMore(notificationsListNew),
                },
                must_to_read: {
                    notifications: notificationsListMust,
                    hasMore: hasMore(notificationsListMust),
                },
                request: {
                    notifications: notificationsListRequest,
                    hasMore: hasMore(notificationsListRequest),
                },
            });
        }
    }, [notificationsListNew, notificationsListMust, notificationsListRequest]);

    useEffect(() => {
        if ((currentCategory === 3 && !notificationsListMust.length) ||
           (currentCategory === 4 && !notificationsListRequest.length)) {
            getNotifications(currentCategory, 1);
        }
    }, [currentCategory]);

    const getNotifications = async (currentCategory, startElement) => {
        if (startElement === 1) setLoaded(false);

        await Request({
            url: `/api/article/notifications?type=${currentCategory}&start_element=${startElement}`,
        }, ({ notifications }) => {
            currentCategory === 2 && setNotificationsListNew(startElement === 1 ?
                [...notifications] : [...notificationsListNew, ...notifications]);
            currentCategory === 3 && setNotificationsListMust(startElement === 1 ?
                [...notifications] : [...notificationsListMust, ...notifications]);
            currentCategory === 4 && setNotificationsListRequest(startElement === 1 ?
                [...notifications] : [...notificationsListRequest, ...notifications]);
        }, error => {
            console.error(error);
        });

        if (startElement === 1) setLoaded(true);
    };

    const getNotificationsCounters = async () => {
        await Request({
            url: `/api/article/notifications_count`,
        }, (data) => {
            const { counter_of_new, counter_of_must_to_read, counter_of_request } = data;

            if (Object.values(data).reduce((a, b) => a + b) === 0) {
                setShowDot(false);
            }

            setCategories([
                { ...categories.find(category => category.id === 2), count: counter_of_new },
                { ...categories.find(category => category.id === 3), count: counter_of_must_to_read },
                { ...categories.find(category => category.id === 4), count: counter_of_request },
            ]);

        }, error => {
            console.error(error);
        });
    };

    const getNextNotifications = async () => {
        if (currentNotificationsList().hasMore) {
            setStartElement(startElement + 10);
            await getNotifications(currentCategory, startElement + 10);
        }
    };

    const handleIconClick = async () => {
        setOpen(!open);
        if (!open) {
            await getNotifications(2, startElement);
            await getNotificationsCounters();
        }
    };

    const currentNotificationsList = () => {
        switch (currentCategory) {
        case 2: return notificationsList.new;
        case 3: return notificationsList.must_to_read;
        case 4: return notificationsList.request;
        default: return notificationsList.new;
        }
    };

    const handleTabClick = async categoryId => {
        setCurrentCategory(categoryId);
        setStartElement(1);
    };

    const buildUrl = (id = '') => {
        return user_type === 1 ? `/user/${alias}/news-feed${id ? '?category_id=' + id : ''}` :
            user_type === 3 ? `/club/${alias}/news-feed${id ? '?category_id=' + id : ''}` :
                user_type === 5 ? `/${alias}/news-feed${id ? '?category_id=' + id : ''}` :
                    user_type === 7 ? `/nbc/${alias}/news-feed${id ? '?category_id=' + id : ''}` :
            `/kennel/${alias}/news-feed${id ? '?category_id=' + id : ''}`;
    };

    const getNewsFeedLink = (noId = false) => {
        if (noId) return buildUrl();

        if (currentCategory === 3) {
            return buildUrl(5);
        } else if (currentCategory === 5) {
            return buildUrl(6);
        } else if (currentCategory === 2) {
            return buildUrl(7);
        } else if (currentCategory === 4) {
            return buildUrl(6);
        } else {
            return buildUrl();
        }
    };

    const handleOutsideClick = e => {
        if (!e?.target.classList.contains('Notifications__icon')) {
            setOpen(false);
        }
    };

    return (
        <div className="Notifications">
            {isAuthenticated &&
                <>
                    <div className="Notifications__icon-wrap">
                        <div className={`Notifications__icon ${open ? ` _active` : ``}`}
                            onClick={handleIconClick}
                            ref={notificationsRef}
                            timeout={isMobile1080 ? 300 : 0}
                        >
                            Уведомления
                        </div>
                        {showDot && <div className="Notifications__icon-dot" />}
                    </div>
                    <PopupModal
                        showModal={open}
                        handleClose={(e) => {
                            if (!notificationsRef?.current?.contains(e.target)) {
                                setOpen(false);
                            }
                        }}
                    >
                        <CSSTransition
                            in={open}
                            timeout={isMobile1080 ? 400 : 0}
                            classNames="Notifications__transition"
                            unmountOnExit
                            onExited={() => {
                                setNotificationsList([]);
                                setCurrentCategory(2);
                            }}
                            mountOnEnter
                        >
                            <div className="Notifications__inner">
                                <div className="Notifications__content">
                                    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                                        <div className="Notifications__title">
                                            <Link
                                                to={() => getNewsFeedLink(true)}
                                                onClick={() => setOpen(false)}
                                            >
                                                Уведомления
                                            </Link>
                                        </div>
                                        <div className="Notifications__tabs">
                                            <NotificationCategories
                                                categories={categories}
                                                currentCategory={currentCategory}
                                                onClick={handleTabClick}
                                            />
                                        </div>
                                        <div className="Notifications__list-wrap">
                                            {!loaded ?
                                                <Loading centered={false} /> :
                                                <div className="Notifications__list">
                                                    <div
                                                        className="Notifications__list-inner"
                                                        id="mobileNotificationsContainer"
                                                    >
                                                        {currentNotificationsList()?.notifications?.length ?
                                                            <InfiniteScroll
                                                                dataLength={currentNotificationsList().notifications.length}
                                                                next={getNextNotifications}
                                                                hasMore={currentNotificationsList().hasMore}
                                                                loader={<Loading centered={false} />}
                                                                scrollableTarget="mobileNotificationsContainer"
                                                                endMessage={
                                                                    <div className="NotificationItem nothing-found">
                                                                        <h4>Уведомлений больше нет</h4>
                                                                        <img src={DEFAULT_IMG.noNews} alt="Уведомлений больше нет" />
                                                                    </div>
                                                                }
                                                            >
                                                                {currentNotificationsList().notifications.map((notification, i) =>
                                                                    <NotificationItem
                                                                        key={i}
                                                                        setOpen={setOpen}
                                                                        {...notification}
                                                                    />
                                                                )}
                                                            </InfiniteScroll> :
                                                            <div className="NotificationItem nothing-found">
                                                                <h4>Здесь будут ваши уведомления</h4>
                                                                <img src={DEFAULT_IMG.noNews} alt="Здесь будут ваши уведомления" />
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="Notifications__list-see-all">
                                                        <Link
                                                            className="btn btn-primary"
                                                            to={() => getNewsFeedLink()}
                                                            onClick={() => setOpen(false)}
                                                        >
                                                            {currentCategory === 3 ? 'Все важные' :
                                                                currentCategory === 2 ? 'Все новые' :
                                                                    'Все заявки'
                                                            }
                                                        </Link>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </OutsideClickHandler>
                                </div>
                            </div>
                        </CSSTransition>
                    </PopupModal>
                </>
            }
        </div>
    );
};

export default connectWidgetLogin(memo(Notifications));
