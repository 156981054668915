import { useState, useMemo, useEffect, useRef } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { getHeaders } from '../utils/request';

// TODO: есть неиспользуемые хуки, почистить
export const useVisibility = (initialVisibility = false) => {
    const [visibility, setVisibility] = useState(initialVisibility);
    const toggleVisibility = () => setVisibility(!visibility);
    const setVisible = () => setVisibility(true);
    const setInvisible = () => setVisibility(false);
    return useMemo(
        () => ({
            visibility,
            toggleVisibility, setVisible, setInvisible,
        }),
        [toggleVisibility, setVisible, setInvisible, visibility],
    );
};

export const useFocus = (initialFocus = false) => {
    const [isFocused, setIsFocused] = useState(initialFocus);
    const setFocused = () => setIsFocused(true);
    const setBlured = () => setIsFocused(false);
    return useMemo(
        () => ({
            isFocused,
            setFocused,
            setBlured,
        }),
        [setFocused, setBlured, isFocused],
    );
};

export const useResourceAndStoreToRedux = (resourceUrl, onSuccessAction, onErrorAction) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const [requestError, setError] = useState(null);

    useEffect(() => {
        let didCancel = false;

        const axiosConfig = {
            url: resourceUrl,
            headers: getHeaders(),
        };

        const fetchData = async () => {
            try {
                setLoading(true);

                const response = await axios(axiosConfig);

                delete response.data.result.bic_number;

                if (!didCancel) {
                    dispatch(onSuccessAction(response.data.result));
                    setLoading(false);
                }
            } catch (error) {
                setError(error.response);

                if (!didCancel) {
                    if (onErrorAction) {
                        dispatch(onErrorAction(error.response.data.errros));
                    }

                    setLoading(false);
                }
            }
        };

        fetchData();

        return () => {
            didCancel = true;
        };
    }, [resourceUrl]);

    return {
        loading,
        requestError,
    };
};

export const usePictureWithUpdate = (endpoint, successAction) => {
    const [state, setState] = useState({ fileInputValue: '', filePreview: null });
    const handleFileInputChange = e => {
        if (e.target.files) {
            const fileInputValue = e.target.files[0];
            setState({ filePreview: URL.createObjectURL(fileInputValue), fileInputValue });
        }
    };
    const clear = () => setState({ ...state, fileInputValue: null });
    const sendFile = () => {
        let didCancel = false;
        const send = async () => {

            if (state.fileInputValue) {
                const data = new FormData();
                data.append('file', state.fileInputValue);
                const config = {
                    url: endpoint,
                    method: 'POST',
                    data: data,
                    headers: getHeaders(true),
                };

                const response = await axios(config);
                successAction(response.data.result);
            }

        };
        if (!didCancel) {
            send();
        }
    };
    return ({
        ...state,
        clear,
        handleFileInputChange,
        sendFile,
    });
};

export const useWrapClassName = (className) => {
    useEffect(() => {
        // Add class on mount
        const wrap = document.getElementById('wrap');
        wrap.classList.add(className);

        return (
            // Remove on Unmount
            () => wrap.classList.remove(className)
        );

    }, [className]);
};

export const useTimeOut = (callback, time) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            callback();
        }, time);
        return () => clearTimeout(timer);
    }, [callback, time]);
};

// Хук useUpdateEffect для сценариев, когда требуется пропустить начальный рендер.
export const useUpdateEffect = (effect, deps) => {
    const isFirstMount = useRef(true);

    useEffect(() => {
        if (isFirstMount.current) {
            isFirstMount.current = false;
        } else {
            return effect();
        }
    }, deps);
};