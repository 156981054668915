import SubmitButton from '../../components/Form/SubmitButton';
import FormControls from '../../components/Form/FormControls';
import FormGroup from '../../components/Form/FormGroup';
import FormField from '../../components/Form/Field';
import FormInput from '../../components/Form/FormInput';
import FormFile from '../../components/Form/FormFile';
import Form from './Form';

export {
    Form,
    SubmitButton,
    FormControls,
    FormGroup,
    FormField,
    FormInput,
    FormFile,
};