import React, { memo, useCallback, useEffect, useState } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import ls from 'local-storage';
import Header from '../components/Layouts/Header';
import FooterMenu from '../components/Layouts/FooterMenu';
import IframePage from '../pages/Static/IframePage';
import { appRoutes } from '../appConfig';
import { LoadableNotFound } from '../appModules';
import { connectAuthUserInfo, connectAuthVisible } from '../pages/Login/connectors';
import PersonalDataModal from '../components/PersonalDataModal';
import { blockContent } from '../utils/blockContent';
import { Request } from '../utils/request';
import useIsMobile from '../utils/useIsMobile';
// отключено по задаче #18675
// import Modal from '../ui/Modal';
// import Button from '../ui/Buttons/Button';
import NotificationsProvider from './context';

import './kendo.scss';
import './index.scss';


const App = ({ history, isAuthenticated, updateUserInfo, updateBanned }) => {
    const [isShowModal, setIsShowModal] = useState(false); // модалка для отображения уведомления о перс. данных, используется на всех страницах

    // отключено по задаче #18675
    // const [isShowClubsInfoModal, setIsShowClubsInfoModal] = useState(false); // модалка для уведомления клубам рфлс
    // const [isDownloaded, setIsDownloaded] = useState(false); // модалка для уведомления клубам рфлс

    const isMobile1080 = useIsMobile(1080);

    // отключено по задаче #18675

    // const isClub = ls.get('user_info')?.user_type === 3;
    // const alias = ls.get('user_info')?.alias;
    // const isInfoClubModalShowed = ls.get('isInfoClubModalShowed');
    //
    // const date = new Date();
    // const startDate = new Date(2024, 3, 23, 17,30, 0);
    // const endDate = new Date(2024, 4, 24, 12,0, 0);
    // const isNeededDate = startDate < date < endDate;

    useEffect(() => {
        const month = new Date().getMonth() + 1;

        if ( month < 3 || month === 12 ) {
            document.body.classList.add('winter');
        }

        if ( month  > 2 && month < 6) {
            document.body.classList.add('spring');
        }
    }, []);

    useEffect(() => {
        checkAlias();

        const unlisten = history.listen(() => checkAlias());

        window.addEventListener('beforeunload', resetFilters);

        return () => {
            window.removeEventListener('beforeunload', resetFilters);
            unlisten();
        };
    }, [isAuthenticated]);

    useEffect(() => {
        blockContent(isShowModal);
    }, [isShowModal]);

    // отключено по задаче #18675
    // if (isClub) {
    //     Request({
    //         url: `/api/Club/public/${alias}`,
    //     }, data => {
    //         if (data?.federation_alias === 'rfls' && isNeededDate && !isInfoClubModalShowed) {
    //             setIsShowClubsInfoModal(true);
    //         }
    //     });
    // }

    const resetFilters = useCallback(() => {
        ls.remove('ClubsFiltersValues');
        ls.remove('FiltersValues');
    }, []);

    const checkAlias = useCallback(() => {
        const profileId = ls.get('profile_id');

        if (profileId) {
            const userInfo = ls.get('user_info') || {};

            Request({
                url: `/api/Alias/profile/${profileId}`,
            }, result => {
                if (result.alias_name !== userInfo.alias) { // перезаписываем алиас, если тот изменился
                    updateUserInfo({ ...userInfo, alias: result.alias_name });
                }
                // перезаписываем данные по статусу персональных данных и ответсвенных лиц в Redux, если они изменились:
                if (result?.personal_data_request_status !== userInfo?.personal_data_request_status ||
                    result?.declarants_assigned !== userInfo?.declarants_assigned) {
                    updateUserInfo({
                        ...userInfo,
                        personal_data_request_status: result.personal_data_request_status,
                        personal_data_request_accepted: result.personal_data_request_accepted,
                        declarants_assigned: result.declarants_assigned,
                    });
                }
                /*на данный момент заблокированные действия (banned) фактически не используется, функционал на будущее;
                добавляем данные по заблокированным действиям в Redux, если они есть:*/
                if (!!result?.banned_actions.length) {
                    updateBanned(result?.banned_actions);
                }
                setIsShowModal(result?.personal_data_request_status === 2 || result?.personal_data_request_status === 3);
            });
        }
    }, []);

    // отключено по задаче #18675
    // const handleClubInfoModalClose = () => {
    //     setIsShowClubsInfoModal(false);
    //     blockContent(false);
    //     ls.set('isInfoClubModalShowed', 'true');
    // };


    return (
        <NotificationsProvider>
            <Header />
            {isShowModal &&
                <PersonalDataModal
                    showModal={isShowModal}
                    setShowModal={setIsShowModal}
                />
            }

            {/* отключено по задаче #18675 */}
            {/*{isShowClubsInfoModal &&*/}
            {/*    <Modal*/}
            {/*        className="clubs-info-modal"*/}
            {/*        headerName="Информация"*/}
            {/*        showModal={isShowClubsInfoModal}*/}
            {/*        withCloseBtn={false}*/}
            {/*    >*/}
            {/*        <p>*/}
            {/*            Уважаемый пользователь!<br></br>*/}
            {/*            Для Вас, как для члена федерации РФЛС, подготовлено важное уведомление.*/}
            {/*            Для ознакомления с ним, пожалуйста, нажмите на ссылку&nbsp;*/}
            {/*            <a*/}
            {/*                href="/static/Uvedomlenie_chlenov_RFLS_o_konferentsii_rassylka.pdf"*/}
            {/*                title="Уведомление членов РФЛС о конференции"*/}
            {/*                download*/}
            {/*                onClick={() => {*/}
            {/*                    setIsDownloaded(true);*/}
            {/*                    ls.set('isInfoClubModalShowed', 'true');*/}
            {/*                }}*/}
            {/*            >*/}
            {/*                скачать уведомление*/}
            {/*            </a>.*/}
            {/*            После скачивания файла вы сможете закрыть это сообщение.*/}
            {/*        </p>*/}
            {/*        <Button*/}
            {/*            primary*/}
            {/*            onClick={() => {*/}
            {/*                handleClubInfoModalClose();*/}
            {/*            }}*/}
            {/*            disabled={!isDownloaded}*/}
            {/*        >*/}
            {/*            Ок*/}
            {/*        </Button>*/}
            {/*    </Modal>*/}
            {/*}*/}
            <Switch>
                {!!appRoutes.length && appRoutes.map(route =>
                    <Route
                        key={route.path}
                        exact={route.exact}
                        path={route.path}
                        component={route.component}
                    />
                )}
                <Route exact={true} path="/results/cac" component={() => <IframePage src="https://tables.rkf.org.ru/Table/tblResExhibitionCAC.aspx" />} />
                <Route exact={true} path="/results/cacib" component={() => <IframePage src="https://tables.rkf.org.ru/Table/tblResExhibitionCACIB.aspx" />} />
                <Route component={LoadableNotFound} />
            </Switch>
            {isMobile1080 &&
                <FooterMenu />
            }
        </NotificationsProvider>
    );
};

export default memo(withRouter(connectAuthVisible(connectAuthUserInfo(App))));