import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

function ScrollToTop({ history, children }) {
    useEffect(() => {
        const unlisten = history.listen((location) => {
            if (
                location.pathname !== '/exhibitions' &&
          location.pathname !== '/specialists' &&
          location.pathname !== '/judges'
            ) {
                window.scrollTo(0, 0);
            }
        });

        return () => {
            unlisten();
        };
    }, []);

    return <>{children}</>;
}

export default withRouter(ScrollToTop);