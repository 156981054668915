import { number, object, ref, string } from 'yup';
import { mailValidator } from '../../../../../../../utils/mailValidator';

export const kennelForm = {
    method: 'POST',
    withLoading: true,
    fields: {
        kennel_id: {
            name: 'kennel_id',
            label: 'Выберете питомник/заводскую приставку',
            placeholder: 'Начните вводить название',
            fieldType: 'reactSelect',
            type: 'select',
        },
        kennel_name_lat: {
            name: 'kennel_name_lat',
            type: 'text',
        },
        mail: {
            name: 'mail',
            type: 'email',
            label: 'Код активации будет отправлен на почту:',
            placeholder: 'Введите Ваш E-mail',
        },
        password: {
            name: 'password',
            type: 'password',
            label: 'Пароль',
            placeholder: 'Введите пароль',
        },
        confirm_password: {
            name: 'confirm_password',
            type: 'password',
            label: 'Подтверждение пароля',
            placeholder: 'Введите подтверждение пароля',
        },
        activation_code: {
            name: 'activation_code',
            type: 'text',
            placeholder: 'Введите код',
        },
    },
    validationSchema: object().shape({
        kennel_id: number().required('Выберете питомник/заводскую приставку'),
        mail: mailValidator().required('Укажите E-mail'),
        password: string()
            .required('Поле не может быть пустым')
            .min(6, 'Пароль должен состоять минимум из 6 символов')
            .matches(/^(?=.*[A-ZА-ЯЁ])(?=.*[0-9])[\w\S].{5,}/g, 'Пароль должен иметь не менее 1 заглавной буквы и не менее 1 цифры'),
        confirm_password: string().required('Поле не может быть пустым').oneOf([ref('password'), null], 'Пароль не совпадает'),
        activation_code: string(),
    }),
};
