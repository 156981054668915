import React, { memo } from 'react';
import ReactDOM from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import Container from '../../components/Layouts/Container';
import './index.scss';


const PopupModal = ({ showModal, handleClose, children, bottomStyle, zIndexStyle, hideFooter }) =>
    ReactDOM.createPortal(
        <div className={`Modal-popup${
            showModal ? ' _show' : ' _hidden'}${
            hideFooter ? ' hideFooter' : ''}${
            bottomStyle ? ' bottomStyle' : ''}${
            zIndexStyle ? ' zIndexStyle' : ''}`}
        >
            <OutsideClickHandler
                onOutsideClick={handleClose}
                disabled={!showModal}
            >
                <Container className="popup__content">
                    {children}
                </Container>
            </OutsideClickHandler>
        </div>,
        document.body
    );

export default memo(PopupModal);