import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '../../../ui/Buttons/Button';

import './index.scss';

const UserAlertBanner = ({ isPersonalData }) => {
    const history = useHistory();
    const user_info = useSelector(state => state.authentication.user_info);
    const userType = user_info?.user_type === 1 ? 'user' : 'kennel';
    const tabNumber = user_info?.user_type === 1 ? 6 : 4;
    const { pathname } = useLocation();

    const onBannerClick = () => {
        history.push(`/${userType}/${user_info.alias}/edit`, { toTab: tabNumber });
    };

    return (
        isPersonalData &&
            (!pathname.match('/clubs-map') &&
                <div className="user-alert-banner" onClick={onBannerClick}>
                    <p className="user-alert-banner__text">
                        Уважаемый пользователь!<br/>
                        Для полноценной работы с порталом RKF.ONLINE Вам необходимо предоставить согласие на обработку
                        персональных данных.<br/>
                        После подтверждения предоставленной информации сотрудниками РКФ Вам не нужно будет прикладывать к
                        каждой отправляемой заявке данный документ».
                    </p>
                    <Button className="user-alert-banner__button">
                        Загрузить
                    </Button>
                </div>
            )
    );
};

export default UserAlertBanner;