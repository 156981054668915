import React, { memo } from 'react';
import classnames from 'classnames';
import './index.scss';


const FormControls = ({ children, className, inline, style }) => (
    <div
        style={style}
        className={classnames(
            'FormControls',
            { 'FormControls--inline': inline },
            { [className]: className },
        )}
    >
        {children}
    </div>
);

export default memo(FormControls);