import React, { memo } from 'react';
import classnames from 'classnames';
import './index.scss';


const FormGroup = ({ children, className, inline, style }) => (
    <div
        style={style}
        className={classnames(
            'FormGroup',
            { 'FormGroup--inline': inline },
            { [className]: className }
        )}
    >
        {children}
    </div>
);

export default memo(FormGroup);