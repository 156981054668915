import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { blockContent } from '../../../../../utils/blockContent';

import './index.scss';


export const ModalAvatar = ({
    showModal,
    handleClose,
    picture,
}) => {

    useEffect(() => {
        blockContent(showModal);
    }, [showModal]);

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                handleClose();
            }
        };
        window.addEventListener('keydown', close);
        return () => window.removeEventListener('keydown', close);
    },[]);

    return (
        ReactDOM.createPortal(
            <div className={showModal ? 'ModalAvatar' : 'ModalAvatar__hidden'}>
                <OutsideClickHandler onOutsideClick={handleClose}>
                    <div className="ModalAvatar__inner">
                        <button className="ModalAvatar__close" onClick={handleClose}></button>
                        <img src={picture} alt="Аватар"/>
                    </div>
                </OutsideClickHandler>
            </div>, document.body
        )
    );
};

export default ModalAvatar;
