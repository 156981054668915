import React, { memo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import ImagePreview from '../../ImagePreview';
import './index.scss';


const ImageInput = ({ id, className, name, placeholder, disabled, onChange, onDrop, onBlur }) => {
    const [src, setSrc] = useState('');

    const handleChange = e => {
        const file = e.target?.files[0];

        setSrc(file ? URL.createObjectURL(file) : '');
        onChange(file || '');
    };

    const handleDrop = files => {
        if (files.length === 1) {
            setSrc(URL.createObjectURL(files[0]));
            onDrop(files[0]);
        }
    };

    const { getRootProps, getInputProps } = useDropzone({ handleDrop, multi: false });

    return (
        <div className="ImageInput" {...getRootProps()}>
            <input
                id={id}
                className={className}
                type="file"
                placeholder={placeholder}
                name={name}
                onChange={handleChange}
                onBlur={onBlur}
                disabled={disabled}
                {...getInputProps()}
            />
            {src && <ImagePreview src={src}/>}
        </div>
    );
};

export default memo(ImageInput);