import React, { memo } from 'react';
import './index.scss';


const Card = ({ className, lg, children, ...rest }) => (
    <div className={`Card${lg ? ' Card--lg' : ''}${className ? ' ' + className : ''}`} {...rest}>
        {children}
    </div>
);

export default memo(Card);