import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';
import './index.scss';


const MenuList = ({ menu, setIsMenuOpen, clickOnPresidium, clickOnDisabledLink }) => (
    <ul className="menu-list">
        {menu.map(menuItem =>
            <li className="menu-list__item" key={menuItem.id}>
                {!menuItem.isPresidium && !menuItem.disabled ?
                    <NavLink
                        to={menuItem.to}
                        exact={!!menuItem.exact}
                        target={menuItem.target || '_self'}
                        className="menu-list__link"
                        onClick={() => setIsMenuOpen(false)}
                    >
                        {menuItem.icon}
                        <span>{menuItem.title}</span>
                    </NavLink>
                    :
                    <span
                        className={`menu-list__link${menuItem.disabled ? ' _disabled' : ''}`}
                        onClick={() => menuItem.isPresidium ?
                            clickOnPresidium(menuItem.alias) :
                            clickOnDisabledLink(menuItem.to === '/bank-details')
                        }
                    >
                        {menuItem.icon}
                        <span>{menuItem.title}</span>
                    </span>
                }
            </li>
        )}
    </ul>
);

export default memo(MenuList);