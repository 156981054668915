import React from 'react';
import { Creatable } from 'react-select';
import { connect, getIn } from 'formik';
import { defaultReactSelectStyles } from './config';

export const NoOptionsMessage = () => {
    return ('Нет опций для выбора');
};

const FormikReactSelectCreatable = ({
    formik,
    options,
    id,
    name,
    className,
    placeholder,
    disabled,
    clearable,
    defaultValue,
    onChange,
    closeMenuOnSelect,
    components,
}) => {
    const getValue = () => {
        const value = getIn(formik.values, name);

        if (options) {
            const val = options.find(option => option.label === value);
            const id = options.reduce((acc, curr) => acc.value > curr.value ? acc : curr, { value:0 });

            return val ? val : { value: id.value + 1, label: value };
        } else {
            return '';
        }
    };

    const handleChange = value => {
        formik.setFieldValue(name, value.label);
        onChange && onChange(value.label);
    };

    const onBlur = e => {
        const newValue = e?.currentTarget?.value;
        const value = getIn(formik.values, name);

        if (newValue && (newValue !== value)) {
            formik.setFieldValue(name, newValue.toUpperCase());
        }

        formik.setFieldTouched(name);
    };

    return (
        <Creatable
            components={{ ...components, NoOptionsMessage }}
            id={id}
            closeMenuOnSelect={closeMenuOnSelect}
            styles={defaultReactSelectStyles.defaultTheme}
            className={className}
            name={name}
            value={getValue()}
            options={options}
            placeholder={placeholder}
            isDisabled={disabled}
            onChange={handleChange}
            onBlur={onBlur}
            clearable={clearable}
            defaultValue={defaultValue}
            isSearchable
            classNamePrefix="RSInput"
            formatCreateLabel={inputValue => `Добавить "${inputValue}" как код стороннего клуба`}
        />
    );
};


export default connect(FormikReactSelectCreatable);