import React, { memo, useState } from 'react';
import { getHeaders } from '../../utils/request';
import Button from '../../ui/Buttons/Button';


const DocLink = ({ docId, label, showLabel, apiEndpoint, file }) => {
    const [isLoading, setIsLoading] = useState(false);
    /* file используется для отображения файла из formik, загруженного через старый загрузчик (task #16046) */
    const openPdfByFormikFile = (file) => {
        const url = URL.createObjectURL(file);
        window.open(url, '_blank');
        URL.revokeObjectURL(url);
    };

    const openDocById = (id, docUrl) => {
        if (!id || !docUrl) return;
        setIsLoading(true);

        fetch(`${docUrl}?id=${id}`, { headers: getHeaders() })
            .then(res => {
                if (!res.ok) {
                    throw new Error(`Ошибка загрузки файла ${res.status}: ${res.statusText}`);
                }
                return res.blob();
            })
            .then(data => URL.createObjectURL(data))
            .then(url => {window.open(url, '_blank');})
            .catch(error => {
                console.error(error.message);
            })
            .finally(() => setIsLoading(false));
    };

    const handleShowButtonClick = () => {
        if (file) {
            openPdfByFormikFile(file);
        } else if (docId && apiEndpoint) {
            openDocById(docId, apiEndpoint, setIsLoading);
        }
    };


    return !!docId &&
        <div className="FormInput">
            <label>{showLabel ? label : '\u00a0'}</label>
            <Button
                secondary
                max
                onClick={handleShowButtonClick}
                rel="noopener noreferrer"
                type="button"
            >
                {isLoading ? 'Загрузка...' : 'Посмотреть'}
            </Button>
        </div>;
};

export default memo(DocLink);