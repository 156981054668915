import React, { useEffect } from 'react';
import MaskedInput from 'react-text-mask';
import { connect, getIn } from 'formik';
import Error from './FieldError';


const FormikInputMasked = ({
    className,
    disabled,
    formik,
    guide = false,
    isPhoneNumber,
    mask,
    name,
    noTouch,
    placeholder,
    showMask = false,
    type,
}) => {
    const formikPhoneNumber = getIn(formik.values, name);

    useEffect(() => {
        // удаляем из формика данные по номеру телефону, если бэка приходит некорректный формат.
        // (актуально для старых номеров телефонов)
        if (isPhoneNumber && !/^[+][7]{1}[(]\d{3}[)]\d{3}[-]\d{2}[-]\d{2}$/.test(formikPhoneNumber)) {
            formik.setFieldValue(name, '');
        }
    }, []);

    return (
        <div className={`FormInput__masked-tel${className ? ' ' + className : ''}`} >
            <MaskedInput
                className={className}
                type={type}
                name={name}
                placeholder={placeholder}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formikPhoneNumber}
                mask={mask}
                guide={guide}
                disabled={disabled}
                showMask={showMask}
            />
            <Error name={name} noTouch={noTouch} />
        </div>
    );
};

export default connect(FormikInputMasked);