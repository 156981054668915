import React from 'react';
import { useSelector } from 'react-redux';
import Modal from '../../ui/Modal';
import Button from '../../ui/Buttons/Button';
import { Request } from '../../utils/request';

import './styles.scss';

const PersonalDataModal = ({ setShowModal, showModal }) => {
    const user_info = useSelector(state => state.authentication.user_info);
    const isApproved = user_info?.personal_data_request_status === 2;
    const isRejected = user_info?.personal_data_request_status === 3;

    // запрос на изменение статуса заявки на "ознакомлен"
    const clickHandler = async () => {
        await Request({
            url: '/api/requests/CommonRequest/personal_data_request',
            method: 'PUT',
        }, () => {
        }, error => {
            console.error(error);
        }
        );
        setShowModal(false);
    };


    return (
        <Modal
            showModal={showModal}
            noBackdrop={true}
            className="status-modal"
            title="Статус заявки"
            headerName="Статус заявки"
            withCloseBtn={false}
        >
            {isApproved &&
                <div className="status-modal__text">
                    <p>
                        Уважаемый пользователь!<br/>
                        Ваша заявка с документом на согласие обработки персональных данных<br/>
                        была одобрена.
                    </p>
                </div>
            }
            {isRejected &&
                <div className="status-modal__text">
                    <p>
                        Уважаемый пользователь!<br/>
                        Вашей заявке с документом на согласие обработки персональных данных отказано.<br/>
                        Более развернутый ответ отправлен Вам на почту, которая указана в качестве
                        логина при регистрации на портале RKF.ONLINE
                    </p>
                </div>
            }
            <Button
                primary
                onClick={clickHandler}
            >
                Ок
            </Button>
        </Modal>
    );
};

export default React.memo(PersonalDataModal);