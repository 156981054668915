import { makeActionCreator } from '../../utils';
import * as actionTypes from './actiontypes';

export const loginUserSuccess = makeActionCreator(actionTypes.LOGIN_SUCCESS, 'data');
export const logOutUser = makeActionCreator(actionTypes.LOGOUT);
export const updateUserInfo = makeActionCreator(actionTypes.UPDATE_USER_INFO, 'data');
export const updateBanned = makeActionCreator(actionTypes.UPDATE_BANNED, 'data');
export const logOutOneUser = makeActionCreator(actionTypes.LOGOUT_ONE_USER, 'data');
export const changeActiveUser = makeActionCreator(actionTypes.CHANGE_ACTIVE_USER, 'data');

