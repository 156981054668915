import React from 'react';
import classnames from 'classnames';
import './index.scss';


export const ButtonNext = ({ className, type = 'button', disabled, onClick, children }) => (
    <button
        className={classnames(
            'btn',
            'btn-light',
            'btn-icon',
            'btn-next',
            { [className]: className }
        )}
        type={type}
        disabled={disabled}
        onClick={onClick}
    >
        <span>{children}</span>
        <img src={'/static/icons/chevron_right.svg'} alt=""/>
    </button>
);

export const ButtonPrev = ({ className, type = 'button', disabled, onClick, children }) => (
    <button
        className={classnames(
            'btn',
            'btn-light',
            'btn-icon',
            'btn-prev',
            { [className]: className }
        )}
        type={type}
        disabled={disabled}
        onClick={onClick}
    >
        <img src={'/static/icons/chevron_left.svg'} alt=""/>
        <span>{children}</span>
    </button>
);

const Button = React.forwardRef((
    {
        style,
        onClick,
        className,
        disabled,
        type = 'button',
        loading,
        children,
        leftIcon,
        rightIcon,
        primary,
        secondary,
        condensed,
        max,
    },
    ref
) => (
    <button
        ref={ref}
        className={classnames(
            'btn',
            { 'btn--loading': loading },
            { 'btn-icon': leftIcon || rightIcon },
            { 'btn-icon--left': leftIcon },
            { 'btn-icon--right': rightIcon },
            { 'btn-primary': primary && !secondary },
            { 'btn-secondary': secondary && !primary },
            { 'btn-condensed': condensed },
            { 'btn-max': max },
            { [className]: className }
        )}
        style={style}
        type={type}
        disabled={disabled || loading}
        onClick={onClick}
    >
        {leftIcon}

        {children}

        {rightIcon}
    </button>
));

export default Button;