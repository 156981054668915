import React, { memo } from 'react';


const Label = ({ label, htmlFor }) => (
    label ?
        <label
            htmlFor={htmlFor}
            dangerouslySetInnerHTML={{ __html: label }}
        />
        : null
);

export default memo(Label);