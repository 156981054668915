import React, { memo, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import OutsideClickHandler from 'react-outside-click-handler';
import { useLocation } from 'react-router-dom';
import Avatar from '../../Avatar';
import Modal from '../../../../ui/Modal';
import PopupModal from '../../../../ui/PopupModal';
import { connectAuthUserInfo, connectWidgetLogin } from '../../../../pages/Login/connectors';
import useIsMobile from '../../../../utils/useIsMobile';
import { blockContent } from '../../../../utils/blockContent';
import LoginWidget from '../../../LoginWidget/LoginWidget';
import Alert from '../../../../ui/Alert';
import Content from './Content/Content';
import LoginAsUser from './LoginAsUser';
import AuthButtons from './AuthButtons';


const WidgetLogin = ({
    open,
    setOpen,
    login_page,
    footerNav,
    loginUserSuccess,
    logOutUser,
    logOutOneUser,
    isAuthenticated,
    is_active_profile,
    account_type,
    helpdesk_api_key,
    user_info,
    multi_accounts,
}) => {
    const [isDesktop, setIsDesktop] = useState(false);
    const [isShowModal, setIsShowModal] = useState(false);
    const [isShowLoginAlert, setIsShowLoginAlert] = useState(false);
    const [isShowLoginModal, setIsShowLoginModal] = useState(false);

    const isMobile1080 = useIsMobile(1080);
    const { alias, name, user_type, first_name, last_name, logo_link, headliner_link, mail } = user_info || {};
    const redirectLink = new URLSearchParams(useLocation().search).get('redirect_url');

    const widgetLoginRef = useRef();
    const history = useHistory();

    useEffect(() => {
        setOpen(isDesktop);
        blockContent(isDesktop);
    }, [isDesktop]);

    useEffect(() => {
        if (redirectLink && helpdesk_api_key && mail) {
            const userName = name || last_name + ' ' + first_name;

            window.location.replace(
                `${redirectLink}${/\?/.test(redirectLink) ? '&' : '?'}t=${helpdesk_api_key}&e=${mail}&n=${userName}`
            );
        }
    }, [redirectLink, helpdesk_api_key, mail]);

    const handleChecked = () => {
        setIsDesktop(!isDesktop);
    };

    const handleOutsideClick = event => {
        if (!widgetLoginRef.current?.contains(event.target)) {
            setIsDesktop(false);
        }
    };

    const handleLogoutOneUser = () => {
        logOutOneUser();

        let userFullAlias = '';

        multi_accounts.forEach(user => {
            if (!user.is_active_account) userFullAlias = user.user_info.user_full_alias;
        });

        history.push(userFullAlias);
    };

    return (
        <div className={`widget-login class-for-grid-block3${!isAuthenticated ? ' __no-auth' : ''}`}>
            {isAuthenticated ?
                <section>
                    <div
                        className={`widget-login__wrap${open ? ' _login_open' : ''}`}
                        ref={widgetLoginRef}
                        onClick={handleChecked}
                    >
                        {isMobile1080 ?
                            <div className="widget-login__user-icon">
                                {footerNav?.image}
                                <span>
                                    {footerNav?.title}
                                </span>
                            </div>
                            :
                            <Avatar
                                data="logo"
                                logo={logo_link}
                                card="user-icon"
                                open={open}
                                userType={user_type}
                                name={name}
                                subclass="user-icon"
                            />
                        }
                        {!isMobile1080 &&
                            <span>
                                Профиль
                            </span>
                        }
                    </div>
                    <OutsideClickHandler onOutsideClick={handleOutsideClick}>
                        <PopupModal
                            showModal={open}
                            handleClose={(event) => {
                                !widgetLoginRef.current?.contains(event.target) && setOpen(false);
                            }}
                            bottomStyle={isMobile1080}
                        >
                            <CSSTransition
                                in={open}
                                timeout={isMobile1080 ? 400 : 0}
                                classNames="widget-login__transition"
                                mountOnEnter
                                unmountOnExit
                            >
                                <div className="widget-login__inner">
                                    <Content
                                        open={open}
                                        setOpen={setOpen}
                                        setShowModal={setIsShowModal}
                                        loginUserSuccess={loginUserSuccess}
                                        logOutUser={logOutUser}
                                        isMobile1080={isMobile1080}
                                        alias={alias}
                                        userType={user_type}
                                        accountType={account_type}
                                        is_active_profile={is_active_profile}
                                        name={name}
                                        lastName={last_name}
                                        firstName={first_name}
                                        logo={logo_link}
                                        menuBackground={headliner_link}
                                        setIsShowLoginAlert={setIsShowLoginAlert}
                                        handleLogoutOneUser={handleLogoutOneUser}
                                        history={history}
                                        isAcceptPersonalData={user_info.personal_data_request_accepted}
                                        userName={user_info?.name}
                                    />
                                </div>
                            </CSSTransition>
                        </PopupModal>
                    </OutsideClickHandler>
                </section> :
                <AuthButtons
                    className="header"
                    login_page={login_page}
                    isOpen={!!redirectLink}
                />
            }
            <Modal
                className="widget-login__modal"
                iconName="enter-white"
                headerName={ `Войти как ${user_type === 5 ? 'клуб' : 'физлицо'}` }
                showModal={isShowModal}
                handleClose={() => setIsShowModal(false)}
            >
                <LoginAsUser
                    closeModal={() => setIsShowModal(false)}
                    user_info={user_info}
                />
            </Modal>
            {isShowLoginAlert &&
                <Alert
                    title="Уважаемый пользователь!"
                    text="Если Ваш браузер открыт в режиме инкогнито,
                                 связь между аккаунтами после закрытия браузера будет разорвана"
                    autoclose={3.5}
                    onOk={() => {
                        setIsShowLoginAlert(false);
                        setIsShowLoginModal(true);
                    }}
                />
            }
            <CSSTransition
                in={isShowLoginModal}
                timeout={350}
                classNames="login-transition"
                unmountOnExit
            >
                <LoginWidget
                    loginTab={1}
                    showModal={isShowLoginModal}
                    setIsShowLoginModal={setIsShowLoginModal}
                    handleClose={() => {
                        setIsShowLoginModal(false);
                    }}
                />
            </CSSTransition>
        </div>
    );
};

export default connectAuthUserInfo(connectWidgetLogin(memo(WidgetLogin)));