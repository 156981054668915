import { DEFAULT_PHONE_INPUT_MASK } from '../../../../appConfig';

export const fieldProps = typeId => {
    let newProps = {};

    if (typeId === 1) {
        newProps = {
            fieldType: 'masked',
            type: 'tel',
            isPhoneNumber: true,
            mask: DEFAULT_PHONE_INPUT_MASK,
            placeholder: '+7(___)___-__-__',
            title: 'Формат номера: +7(999)999-99-99',
            noTouch: true,
        };
    }

    return newProps;
};