import React, { memo } from 'react';
import { Link } from 'react-router-dom';
import Avatar from '../../../Layouts/Avatar';
import { judgeIcon } from '../../UserLayout/config';
import './index.scss';


const MenuHeader = ({ currentPageUserInfo, setIsMenuOpen }) => {
    const isJudge = !!currentPageUserInfo?.open_roles?.find(item => item.key_name === 'role_judge');
    const getLinkForName = () => {
        switch (currentPageUserInfo?.user_type) {
        case 1:
            return `/user/${currentPageUserInfo?.alias}`;
        case 3:
            if (currentPageUserInfo?.club_alias === 'rkf') {
                return '/rkf';
            }

            return `/club/${currentPageUserInfo?.club_alias ? currentPageUserInfo?.club_alias : currentPageUserInfo?.alias}`;
        case 4:
            return `/kennel/${currentPageUserInfo?.alias}`;
        case 5:
            return `/${currentPageUserInfo?.club_alias ? currentPageUserInfo?.club_alias : currentPageUserInfo?.alias}`;
        case 7:
            return `/nbc/${currentPageUserInfo?.alias}`;
        default:
            return '';
        }
    };

    const getName = () => {
        switch (currentPageUserInfo?.user_type) {
        case 1:
            return currentPageUserInfo?.personal_information ?
                    `${currentPageUserInfo.personal_information.first_name} ${currentPageUserInfo.personal_information.last_name}` :
                    `${currentPageUserInfo.name}`;
        case 3:
            if (currentPageUserInfo?.club_alias === 'rkf') {
                return currentPageUserInfo?.federation_name ?
                        `${currentPageUserInfo?.federation_name}` :
                        `${currentPageUserInfo.name}`;
            }

            return currentPageUserInfo?.short_name ?
                    `${currentPageUserInfo?.short_name}` :
                    `${currentPageUserInfo.name}`;
        case 4:
            return `${currentPageUserInfo?.name}`;
        case 5:
            return currentPageUserInfo?.federation_name ?
                    `${currentPageUserInfo?.federation_name}` :
                    `${currentPageUserInfo?.name}`;
        case 7:
            return `${currentPageUserInfo?.name}`;
        default:
            return '';
        }
    };

    const getHeadlinerLink = () => {
        if (currentPageUserInfo?.headliner_link) {
            return currentPageUserInfo.headliner_link;
        } else if (currentPageUserInfo?.club_alias === 'rkf') {
            return '/static/images/slider/1.jpg';
        }

        return '/static/images/widget-login/userpic-bg.jpg';
    };

    const getLogoLink = () => {
        if (currentPageUserInfo?.logo_link) {
            return currentPageUserInfo.logo_link;
        }

        return '';
    };

    return (
        <div className="menu-header">
            <div className="menu-header__bg-wrap">
                <img src={getHeadlinerLink()} alt="menu-background" className="menu-header__bg"/>
                <div className="menu-header__avatar">
                    <Avatar
                        card="mobile-user-menu"
                        data="mobile-user-menu"
                        logo={getLogoLink()}
                        name={getName()}
                        userType={currentPageUserInfo?.user_type}
                    />
                </div>
            </div>
            <div className="menu-header__name">
                <Link
                    to={getLinkForName()}
                    className="menu-header__name-link"
                    onClick={() => setIsMenuOpen(false)}
                >
                    <span className="menu-header__name-title">
                        {getName()}
                    </span>
                    {isJudge &&
                        <span className="menu-header__name-icon">
                            {judgeIcon}
                        </span>
                    }
                </Link>
            </div>
        </div>
    );
};

export default memo(MenuHeader);