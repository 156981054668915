import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Card from '../Card';
import useIsMobile from '../../utils/useIsMobile';
import personalDataProcessingPolicyDoc from '../../pages/PersonalDataProcessingPolicy/politic(120722).pdf';
import './index.scss';


const CopyrightInfo = ({ withSocials }) => {
    const isMobile = useIsMobile();
    const isAuth = useSelector(state => state.authentication.isAuthenticated);

    return isMobile ? null : (
        withSocials
            ? <Card className="copyright-wrap">
                <div className="copyright-info with-socials">
                    <div className="copyright-socials">
                        <a target="_blank" rel="noopener noreferrer" href="https://vk.com/ruskynologfed">
                            <img src="/static/icons/social/vk.svg" alt="" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://t.me/RkfOnlineOfficial">
                            <img src="/static/icons/social/telegram.svg" alt="" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UC1mzNt3TccDxGfA-vkEAQig">
                            <img src="/static/icons/social/youtube.svg" alt="" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://rutube.ru/channel/24811120/">
                            <img src="/static/icons/social/rutube.svg" alt="" />
                        </a>
                        <a target="_blank" rel="noopener noreferrer" href="https://dzen.ru/rkf_org">
                            <img src="/static/icons/social/dzen.svg" alt="" />
                        </a>
                    </div>
                    <div className="copyright-year">
                        <span>© 1991—{new Date().getFullYear()} СОКО РКФ.</span>
                    </div>
                    <div className="copyright-about">
                        {isAuth &&
                            <a className="copyright-feedback"
                                href="https://info.rkf.online/my_questions"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Связаться с разработчиками
                            </a>
                        }
                        <Link to="/about" className="copyright-link">О RKF.Online</Link>
                    </div>
                    <div>
                        <Link
                            className="copyright-link copyright-policy"
                            to={personalDataProcessingPolicyDoc}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Политика обработки персональных данных
                        </Link>
                    </div>
                </div>
            </Card>
            : <div className="copyright-info">
                <p>© 1991—{new Date().getFullYear()} СОКО РКФ.</p>
                <Link
                    className="copyright-policy"
                    to={personalDataProcessingPolicyDoc}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Политика обработки персональных данных
                </Link>
            </div>
    );
};

export default memo(CopyrightInfo);