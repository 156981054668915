import createReducer from '../../utils/createReducer';
import * as actionTypes from './actionTypes';

const initialState = {
    isOpenFilters: false,
};

const layoutReducer = createReducer(initialState, {
    [actionTypes.SET_SHOW_FILTERS](state, action) {
        return { ...state, ...action.data };
    },
});

export default layoutReducer;