import React, { memo, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Alert from '../../../../../../../ui/Alert';
import { Form, FormField } from '../../../../../../Form';
import { Request } from '../../../../../../../utils/request';
import CustomCheckbox from '../../../../../../../ui/FormComponents/CustomCheckbox';
import personalDataProcessingPolicyDoc from '../../../../../../../pages/PersonalDataProcessingPolicy/Politika_PDn.pdf';
import personalDataProcessingPolicyPDF from '../../../../../../../pages/PersonalDataProcessingPolicy/PPDn.pdf';
import { kennelForm } from './config';

import './index.scss';


const KennelRegistration = () => {
    const [kennels, setKennels] = useState([]);
    const [searchContent, setSearchContent] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [kennel, setKennel] = useState(null);
    const [chosenKennel, setChosenKennel] = useState('');
    const [isCodeFormSend, setIsCodeFormSend] = useState(false);
    const [code, setCode] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [alert, setAlert] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [isChecked, setIsChecked] = useState(false);

    const history = useHistory();

    useEffect(() => {
        if (searchContent?.length >= 2 && (searchValue === searchContent)) {
            foundKennels();
        }
    }, [searchContent]);

    const foundKennels = async () => {
        await Request({
            url: `/api/Kennel/list/find?query=${searchContent}`,
        }, data => {
            setKennels(data.map(option => ({ label: option.name, value: option.kennel_id, kennel_name_lat: option.name_intl })));
        }, error => {
            console.error(error.response);
        });
    };

    const transformKennelValues = values => {
        let newData = { ...values };

        setKennel(newData);

        return {
            mail: newData.mail,
        };
    };

    const kennelFormSuccess = () => {
        setCode('');
        setIsCodeFormSend(true);
    };

    const transformCodeValues = values => {
        return {
            ...values,
            ...kennel,
            activation_code: values.activation_code,
        };
    };

    const codeFormSuccess = () => {
        setAlertText('Мы отправили Вам письмо на указанный вами адрес. Пожалуйста, зайдите в свою почту и следуйте дальнейшим инструкциям.');
        setAlert(true);
    };

    const handleFormError = error => {
        if (error.response && error.response.data && error.response.data.errors) {
            setAlertText(`${Object.values(error.response.data.errors)}`);
        }

        setAlert(true);
    };

    const handleCodeInput = (e) => {
        const value = e.target.value;
        if (!value || !!value.match(/\D/g)) {
            setErrorMessage('Введите код');
        } else {
            setErrorMessage(null);
            setCode(value);
        }
    };

    const onPrivacyChange = () => {
        setIsChecked(!isChecked);
    };

    const handleKennelChange = (value) => {
        setChosenKennel(value);
    };

    const handleKennelSearch = value => {
        setSearchValue(value);

        setTimeout(() => {
            setSearchContent(value);
        }, 1000);
    };

    return (
        <div className="kennel-registration">
            <div className="kennel-registration__activate-form">
                <Form
                    { ...kennelForm }
                    initialValues={ {
                        ...kennel,
                        kennel_id: '',
                        kennel_name_lat: '',
                        mail: '',
                        password: '',
                        confirm_password: '',
                        activation_code: '',
                    } }
                    action={isCodeFormSend ? '/api/Registration/kennel/on_exists' :  '/api/Registration/nursery/send_activation_code'}
                    transformValues={isCodeFormSend ? transformCodeValues : transformKennelValues}
                    onSuccess={isCodeFormSend ? codeFormSuccess : kennelFormSuccess}
                    onError={handleFormError}
                    className="kennel-registration__activate-form"
                >
                    { code === null &&
                        <>
                            <FormField { ...kennelForm.fields.kennel_id } options={ kennels }
                                onInput={ e => handleKennelSearch(e) }
                                onChange={value => handleKennelChange(value)}
                            />
                            { !!chosenKennel?.kennel_name_lat &&
                                <FormField { ...kennelForm.fields.kennel_name_lat} value={chosenKennel.kennel_name_lat} blockIfHasValue={true} />
                            }
                            <FormField { ...kennelForm.fields.mail } />
                            <FormField { ...kennelForm.fields.password } />
                            <FormField { ...kennelForm.fields.confirm_password } />
                            <div className="kennel-registration__data-privacy">
                                <CustomCheckbox
                                    id="kennel-registration__data-privacy"
                                    checked={!!isChecked}
                                    onChange={onPrivacyChange}
                                />
                                <label htmlFor="kennel-registration__data-privacy">
                                    Я принимаю условия&nbsp;
                                    <Link
                                        to={personalDataProcessingPolicyDoc}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Пользовательского соглашения&nbsp;
                                    </Link>
                                    и даю свое согласие на обработку моей персональной информации на условиях, определенных&nbsp;
                                    <Link
                                        to={personalDataProcessingPolicyPDF}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Политикой&nbsp;конфиденциальности.
                                    </Link>
                                </label>
                            </div>

                            <button className="btn btn-primary" type="submit" disabled={!isChecked}>Отправить</button>
                        </>
                    }
                    { code !== null &&
                        <div className="kennel-registration__activate-email">
                            <p>Мы отправили письмо с проверочным кодом на указанный вами
                                адрес: <b>{ kennel.mail || '' }</b></p>
                            <p>Пожалуйста, зайдите в свою почту и введите полученный код ниже.</p>
                            <div className="kennel-registration__form-code">
                                <FormField { ...kennelForm.fields.activation_code } onInput={handleCodeInput} />
                                {errorMessage &&
                                <div className="FormInput__error">{ errorMessage }</div>
                                }
                                <button className="btn btn-primary" type={!errorMessage && !!code ? 'submit' : 'button'}>Активировать</button>
                            </div>
                        </div>
                    }
                </Form>
            </div>
            {alert &&
                <Alert
                    title={isCodeFormSend ? 'Проверьте почту' : 'Произошла ошибка! =('}
                    text={alertText || 'Попробуйте повторить попытку позже, либо воспользуйтесь формой обратной связи.'}
                    okButton={true}
                    onOk={() => {
                        setAlertText('');
                        setAlert(false);
                        if (isCodeFormSend) {
                            history.push('/auth/login');
                        }
                    }}
                />
            }
        </div>
    );
};

export default memo(KennelRegistration);
