import React from 'react';
import { connect, FieldArray } from 'formik';
import { FormField, FormGroup } from '../../index';
import Button from '../../../../ui/Buttons/Button';
import { fieldProps } from './config';
import './index.scss';

//typeId: 1 = 'phone' || 2 = 'email' || 3 = 'web_sites' || 4 = 'social_networks'

const FormikContacts = ({ formik, typeId, name, title, className, addButtonTitle }) => (
    <FieldArray
        name={name}
        render={arrayHelpers => (
            <div className={`contacts${className ? ' ' + className : ''}`}>
                <h4 className="contacts__title">{title}</h4>
                {formik.values[name].map((item, i) =>
                    <FormGroup key={i} className="contacts__item">
                        <FormField
                            className="contacts__item-value"
                            name={`${name}[${i}].value`}
                            placeholder={
                                typeId === 1 ? '' :
                                    typeId === 2 ? 'Введите email' :
                                        'Введите ссылку'
                            }
                            {...fieldProps(typeId)}
                        />
                        <FormField
                            className="contacts__item-description"
                            name={`${name}[${i}].description`}
                            placeholder="Введите описание"
                        />
                        <div className="contacts__item-controls">
                            {typeId !== 4 &&
                                <div className="contacts__item-radio">
                                    {i === 0 &&
                                        <div className="contacts__item-radio-title">Основной</div>
                                    }
                                    <FormField
                                        className="contacts__item-radio-input"
                                        name={`${name}[${i}].is_main`}
                                        fieldType="radioButton"
                                        onChange={e => {
                                            if (e.target.checked) {
                                                formik.values[name].forEach((value, index) => {
                                                    if (index !== i) {
                                                        arrayHelpers.replace(index, { ...value, is_main: false });
                                                    }
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            }
                            <Button
                                className="contacts__item-delete"
                                type="button"
                                onClick={() => arrayHelpers.remove(i)}
                            />
                        </div>
                    </FormGroup>
                )}
                <div className="contacts__add-btn-wrap">
                    <Button
                        className={formik.values[name].length ? 'contacts__add-btn' : 'btn-green'}
                        type="button"
                        disabled={!!formik.values[name].find(value => value.value === '')}
                        onClick={() => {
                            let contact = {
                                value: '',
                                description: '',
                            };

                            if (typeId !== 4) {
                                contact = {
                                    ...contact,
                                    is_main: !formik.values[name].find(value => value.is_main),
                                    contact_type_id: typeId,
                                };
                            }

                            arrayHelpers.push(contact);
                        }}
                    >
                        {addButtonTitle}
                    </Button>
                </div>
            </div>
        )}
    />
);

export default connect(FormikContacts);