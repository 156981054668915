import React, { memo } from 'react';
import Layout from '../../../../components/Layouts';
import AuthLayout from '../../../../components/Layouts/AuthLayout';
import RecoveryContent from './RecoveryContent';

import './index.scss';


const PasswordRecovery = ({ isModal = false }) => {
    return (
        <>
            {isModal ?
                <RecoveryContent/>
                :
                <Layout>
                    <AuthLayout className="password-recovery">
                        <RecoveryContent/>
                    </AuthLayout>
                </Layout>
            }
        </>
    );
};

export default memo(PasswordRecovery);
