import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import AuthCard from '../Layouts/AuthLayout/components/AuthCard/AuthCard';
import { blockContent } from '../../utils/blockContent';
import PasswordRecovery from '../../pages/Login/components/PasswordRecovery';
import { connectAuthUserInfo } from '../../pages/Login/connectors';

import './index.scss';

const LoginWidget = ({ showModal, handleClose, loginTab, setIsShowLoginModal }) => {
    const [isRecovery, setIsRecovery] = useState(false);
    const [errorAlert, setErrorAlert] = useState(false);

    const toggleAuthToRecovery = () => {
        setIsRecovery(!isRecovery);
    };

    useEffect(() => {
        blockContent(showModal);

        return () => blockContent(false);
    }, [showModal]);


    return (
        ReactDOM.createPortal(
            <div className={`login-modal${!showModal ? ' _hidden' : ''}`}>
                <OutsideClickHandler onOutsideClick={handleClose} disabled={!!errorAlert}>
                    <div className="login-modal__inner">
                        <div className="login-modal__content" id="login-modal_scroll_top">
                            {/*Кнопки закрыть и назад оставляем только для состояния восстановления пароля, ТЗ #15828*/}
                            {isRecovery &&
                                <>
                                    <button
                                        className="login-modal__back"
                                        onClick={() => toggleAuthToRecovery()}
                                    >
                                        назад
                                    </button>
                                    <button
                                        className="login-modal__close"
                                        onClick={handleClose}
                                    >
                                        Закрыть
                                    </button>
                                </>
                            }
                            {!isRecovery ?
                                <AuthCard
                                    loginTab={loginTab}
                                    toggleAuthToRecovery={toggleAuthToRecovery}
                                    setIsShowLoginModal={setIsShowLoginModal}
                                    errorAlert={errorAlert}
                                    setErrorAlert={setErrorAlert}
                                />
                                :
                                <PasswordRecovery
                                    isModal={true}
                                />
                            }
                        </div>
                    </div>
                </OutsideClickHandler>
            </div>, document.body
        )
    );
};

export default connectAuthUserInfo(LoginWidget);
