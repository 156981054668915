import React, { memo, useState } from 'react';
import Card from '../../../../ui/Card';
import Alert from '../../../../ui/Alert';
import { Form, FormField, SubmitButton } from '../../../../components/Form';
import { PassRecoveryForm } from './config';


const RecoveryContent = () => {
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);

    const onError = error => {
        if (error.response && error.response.data && error.response.data.errors) {
            setError(error.response.data.errors.error);
        }
    };

    return (
        <>
            {!success ?
                <Card className="password-recovery">
                    <h2 className="password-recovery__title">
                        Забыли пароль?
                    </h2>
                    <p className="password-recovery__subtitle">
                        Введите E-mail, указанный вами при регистрации. Мы вышлем вам инструкции.
                    </p>
                    <Form
                        className="login-page__form"
                        onSuccess={() => setSuccess(true)}
                        onError={onError}
                        withLoading={true}
                        {...PassRecoveryForm}
                    >
                        <FormField {...PassRecoveryForm.fields.mail} />
                        <SubmitButton className="btn btn-primary">Отправить</SubmitButton>
                    </Form>
                </Card>
                :
                <Card className="password-recovery">
                    <h2 className="password-recovery__title">
                        Новый пароль отправлен
                    </h2>
                    <p className="password-recovery__subtitle">
                        Пожалуйста, проверьте свою электронную почту, чтобы продолжить.
                    </p>
                </Card>
            }
            {error &&
                <Alert
                    title="Ошибка!"
                    text={error}
                    onOk={() => setError(null)}
                />
            }
        </>
    );
};

export default memo(RecoveryContent);
