import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import LoginWidget from '../../../LoginWidget/LoginWidget';
import { headerIcons } from '../../../../styles/headerIcons';
import history from '../../../../utils/history';


const AuthButtons = ({ login_page, className, isOpen, isRegistrationHidden }) => {
    const [showModal, setShowModal] = useState(isOpen);
    const [activeTab, setActiveTab] = useState(1);

    const loginRef = useRef();
    const registrationRef = useRef();

    const location = useLocation();

    useEffect(() => {
        location.hash === '#login' && !!loginRef.current && loginRef.current.click();
        location.hash === '#registration' && !!registrationRef.current && registrationRef.current.click();
    }, []);

    const handleIconClick = type => {
        if (type !== activeTab) {
            setActiveTab(type);
        }

        setShowModal(true);
    };

    return (
        <>
            {!login_page &&
                <button
                    className={`${className === 'header' ? 'login-link padding-for-header-title' : className + ' class-for-grid-block6'}`}
                    onClick={() => handleIconClick(1)}
                    ref={loginRef}
                >
                    {headerIcons.login}
                    <span>Вход</span>
                </button>
            }
            {!login_page && !isRegistrationHidden &&
                <button
                    className={`${className === 'header' ? 'registration-link' : className + ' class-for-grid-block5'}`}
                    onClick={() => handleIconClick(2)}
                    ref={registrationRef}
                >
                    {headerIcons.registration}
                    <span>Регистрация</span>
                </button>
            }
            <CSSTransition
                in={showModal}
                timeout={350}
                classNames="login-transition"
                unmountOnExit
                onExited={() => setActiveTab(0)}
            >
                <LoginWidget
                    loginTab={activeTab}
                    showModal={showModal}
                    handleClose={event => {
                        (!loginRef.current.contains(event.target) &&
                            !registrationRef.current.contains(event.target)) &&
                            setShowModal(false);
                        (location.hash === '#login' ||
                            location.hash === '#registration') &&
                            history.push(location.pathname);
                    }}
                />
            </CSSTransition>
        </>
    );
};

export default AuthButtons;
