import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form, FormGroup, FormField, SubmitButton } from '../../../../Form';
import Error from '../../../../Form/Field/FieldError';
import CustomCheckbox from '../../../../../ui/FormComponents/CustomCheckbox';
import { connectAuthUserInfo, connectLogin } from '../../../../../pages/Login/connectors';
import { loginFormConfig } from './config';

import './index.scss';


const { fields } = loginFormConfig;

const LoginForm = ({ loginUserSuccess, toggleAuthToRecovery, setIsShowLoginModal }) => {
    const [rememberMe, setRememberMe] = useState(false);
    const [isShowPassword, setIsShowPassword] = useState(false);
    const [isShowMessage, setIsShowMessage] = useState(false);
    const [loginData, setLoginData] = useState(null);
    const [isNotNewUser, setIsNotNewUser] = useState(true);

    const history = useHistory();
    const multiAccounts = useSelector(state => state.authentication?.multi_accounts);
    const fullAlias = useSelector(state => state.authentication?.user_info?.user_full_alias);

    useEffect(() => {
        // Если это не новый пользователь и fullAlias в Redux обновился в соответсвии с новым алиасом с бэка,
        // то перенаправляем пользователя на страницу с fullAlias.
        if (!isNotNewUser && fullAlias?.includes(loginData?.user_info.alias)) {
            history.push(fullAlias);
            if (setIsShowLoginModal) setIsShowLoginModal(false);
        }

    }, [isNotNewUser, fullAlias]);

    useEffect(() => {
        if (!isNotNewUser && loginData) loginUserSuccess(loginData);
    }, [isNotNewUser, loginData]);

    const onSuccess = data => {
        if (!isNotNewUser) setIsNotNewUser(true);

        if (data) {
            setLoginData(data);
            checkIsNewUser(data?.profile_id);
        }
    };

    const checkIsNewUser = profileId => {
        let iteration = 0;

        if (multiAccounts && !!multiAccounts.length) {
            multiAccounts.forEach(user => {
                iteration++;

                if (user.profile_id === profileId) {
                    setIsShowMessage(true);
                } else if (iteration === multiAccounts.length) {
                    setIsNotNewUser(false);
                }
            });
        } else {
            setIsNotNewUser(false);
        }
    };

    const transformValues = values => {
        const profileIds = [];
        if (multiAccounts) multiAccounts.forEach(user => profileIds.push(user.profile_id));

        return {
            ...values,
            profile_ids: profileIds,
        };
    };


    return (
        <Form
            className="login-page__form"
            initialValues={{ email: '', password: '' }}
            onSuccess={(data) => onSuccess(data)}
            withLoading={true}
            transformValues={transformValues}
            {...loginFormConfig}
        >
            <FormGroup>
                <FormField
                    {...fields.email}
                />
                <FormField
                    {...fields.password}
                    type={isShowPassword ? `text` : `password`}
                />
                <button
                    className={`login-page__form-psw ${isShowPassword ? `_show-psw` : ``}`}
                    type="button"
                    onClick={() => setIsShowPassword(!isShowPassword)}
                >
                </button>
                <Error name="authentication" noTouch={true} />
            </FormGroup>
            <FormGroup inline>
                <CustomCheckbox
                    id="remember-me"
                    label="Запомнить меня"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                />
                <button
                    className="login-page__recovery-link"
                    type="button"
                    onClick={toggleAuthToRecovery}
                >
                    Забыли пароль?
                </button>
            </FormGroup>
            <div className="login-page__form-controls">
                <SubmitButton className="btn-primary btn-lg">Войти</SubmitButton>
            </div>

            {isShowMessage &&
                <div className="login-page__doble-login-message">
                    <span>Уважаемый пользователь!</span>
                    <span>Вы уже прикрепили данный аккаунт.</span>
                </div>
            }
        </Form>
    );
};

export default connectAuthUserInfo(connectLogin(memo(LoginForm)));