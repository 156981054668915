import React, { memo } from 'react';
import { useVisibility } from '../../shared/hooks';
import './index.scss';


const ImagePreview = ({ src, disableClick = false }) => {
    const { visibility, setVisible, setInvisible } = useVisibility(false);

    return (
        <>
            <div className="ImagePreview" onClick={!disableClick ? setVisible : null}>
                <img src={src} alt=""/>
            </div>
            {visibility &&
                <div className="ImagePreview__full" onClick={!disableClick ? setInvisible : null}>
                    <img  src={src} alt=""/>
                </div>
            }
        </>
    );
};

export default memo(ImagePreview);