const t = {
    'а':'a',
    'б':'b',
    'в':'v',
    'г':'g',
    'д':'d',
    'е':'e',
    'ё':'yo',
    'ж':'zh',
    'з':'z',
    'и':'i',
    'й':'j',
    'к':'k',
    'л':'l',
    'м':'m',
    'н':'n',
    'о':'o',
    'п':'p',
    'р':'r',
    'с':'s',
    'т':'t',
    'у':'u',
    'ф':'f',
    'х':'kh',
    'ц':'cz',
    'ч':'ch',
    'ш':'sh',
    'щ':'shch',
    'ы':'y`',
    'ь':'',
    'ъ':'',
    'э':'e',
    'ю':'yu',
    'я':'ya',
    'А':'A',
    'Б':'B',
    'В':'V',
    'Г':'G',
    'Д':'D',
    'Е':'E',
    'Ё':'YO',
    'Ж':'ZH',
    'З':'Z',
    'И':'I',
    'Й':'J',
    'К':'K',
    'Л':'L',
    'М':'M',
    'Н':'N',
    'О':'O',
    'П':'P',
    'Р':'R',
    'С':'S',
    'Т':'T',
    'У':'U',
    'Ф':'F',
    'Х':'KH',
    'Ц':'CZ',
    'Ч':'CH',
    'Ш':'SH',
    'Щ':'SHCH',
    'Ы':'Y',
    'Ь':'',
    'Ъ':'',
    'Э':'E',
    'Ю':'YU',
    'Я':'YA',
};

const transliterate = s =>
    s
        .replace(/[ьъ]/ig, '')
        .replace(/[А-яёЁ]/g, m => t[m] || m);

export default transliterate;
