import createReducer from '../../utils/createReducer';
import * as actiontypes from './actiontypes';

const loadApiKey = () => {
    const key = localStorage.getItem('apikey');
    return key === null ? false : key;
};
const saveApiKey = key => {
    localStorage.setItem('apikey', key);
};
const clearApiKey = () => {
    localStorage.removeItem('apikey');
};
const loadRoles = () => {
    const roles = localStorage.getItem('roles');
    return roles === null ? false : JSON.parse(roles);
};
const saveRoles = roles => {
    localStorage.setItem('roles', JSON.stringify(roles));
};
const clearRoles = () => {
    localStorage.removeItem('roles');
};
const loadUserInfo = () => {
    const user_info = localStorage.getItem('user_info');
    return user_info === null ? null : JSON.parse(user_info);
};

const saveUserInfo = user_info => {
    const user_type_name = getUserTypeName(user_info);
    const user_type_alias = getUserTypeAlias(user_info);
    const user_full_alias = getUserFullAlias(user_info);

    localStorage.setItem('user_info', JSON.stringify({
        ...user_info,
        user_type_name,
        user_type_alias,
        user_full_alias,
    }));
};

const clearUserInfo = () => {
    localStorage.removeItem('user_info');
};

const saveBanned = banned => {
    localStorage.setItem('banned', JSON.stringify(banned));
};
const clearBanned= () => {
    localStorage.setItem('banned', JSON.stringify([]));
};
const loadIsActiveProfile = () => {
    const is_active_profile = localStorage.getItem('is_active_profile');
    return is_active_profile === null ? false : JSON.parse(is_active_profile);
};
const saveIsActiveProfile = is_active_profile => {
    localStorage.setItem('is_active_profile', JSON.stringify(is_active_profile));
};
const clearIsActiveProfile = () => {
    localStorage.removeItem('is_active_profile');
};

const loadPersonalAccess = () => {
    const personal_office_access = localStorage.getItem('personal_office_access');
    return personal_office_access === null ? false : JSON.parse(personal_office_access);
};
const savePersonalAccess = personal_office_access => {
    localStorage.setItem('personal_office_access', JSON.stringify(personal_office_access));
};
const clearPersonalAccess = () => {
    localStorage.removeItem('personal_office_access');
};

const loadProfile = () => {
    const profile_id = localStorage.getItem('profile_id');
    return profile_id === null ? null : parseInt(profile_id, 10);
};
const saveProfile = profile_id => {
    localStorage.setItem('profile_id', JSON.stringify(profile_id));
};
const clearProfile = () => {
    localStorage.removeItem('profile_id');
};

const loadAccountType = () => {
    const account_type = localStorage.getItem('account_type');
    return account_type === null ? null : parseInt(account_type, 10);
};

const saveAccountType = account_type => {
    localStorage.setItem('account_type', JSON.stringify(account_type));
};
const clearAccountType = () => {
    localStorage.removeItem('account_type');
};

const loadHelpdeskApiKey = () => {
    const helpdesk_api_key = localStorage.getItem('helpdesk_api_key');
    return helpdesk_api_key === null ? null : helpdesk_api_key.replace(/['"]+/g, '');
};
const saveHelpdeskApiKey = helpdesk_api_key => {
    helpdesk_api_key && localStorage.setItem('helpdesk_api_key', JSON.stringify(helpdesk_api_key));
};
const clearHelpdeskApiKey = () => {
    localStorage.removeItem('helpdesk_api_key');
};

const saveToken = access_token => {
    const tokens = localStorage.getItem('tokens') ? JSON.parse(localStorage.getItem('tokens')) : [];

    if (!tokens.length || !tokens?.includes(access_token)) {
        tokens.push(access_token);
    }

    localStorage.setItem('tokens', JSON.stringify(tokens));
};

const clearTokens = () => {
    localStorage.removeItem('tokens');
};

const removeToken = token => {
    const tokens = JSON.parse(localStorage.getItem('tokens'));
    localStorage.setItem('tokens',
        JSON.stringify(tokens.filter(item => item !== token)));
};

const getMultiAccounts = (
    // нужно для корректной работы обновления с мульти-аккаунтами
    access_token = loadApiKey(),
    is_active_profile = loadIsActiveProfile(),
    personal_office_access = loadPersonalAccess(),
    user_info = loadUserInfo(),
    profile_id = loadProfile(),
    account_type = loadAccountType(),
    helpdesk_api_key = loadHelpdeskApiKey(),
    roles = loadRoles(),
) => {
    const multiAccounts = localStorage.getItem('multi_accounts') ? JSON.parse(localStorage.getItem('multi_accounts')) : [];
    const user_type_name = getUserTypeName(user_info);
    const user_type_alias = getUserTypeAlias(user_info);
    const user_full_alias = getUserFullAlias(user_info);
    const apiKey = loadApiKey();

    const tokens = [];
    multiAccounts.forEach(user => {
        tokens.push(user.access_token);
        user.is_active_account = user.access_token === apiKey;
    });

    const accountInfo = {
        is_active_account: true,
        access_token,
        is_active_profile,
        personal_office_access,
        user_info : {
            ...user_info,
            user_type_name,
            user_type_alias,
            user_full_alias,
        },
        profile_id,
        account_type,
        api_key: helpdesk_api_key,
        roles,
    };

    // если зашли под федерацией в клуб/питомник, а под этим аккаунтом уже был выполнен вход,
    // то убираем этот аккаунт из мульти
    multiAccounts.filter(user => user.profile_id !== accountInfo.profile_id);

    // если зашли под федерацией в клуб/питомник, то убираем федерацию
    if (accountInfo.account_type === 5 && accountInfo.user_info.user_type_name !== 'federation') {
        return multiAccounts.filter(user => user.user_info.user_type_name !== 'federation');
    }

    // если зашли под админом в физика, то убираем админа
    if (accountInfo.account_type === 101 && accountInfo.user_info.user_type_name !== 'admin') {
        return multiAccounts.filter(user => user.user_info.user_type_name !== 'admin');
    }

    if (!multiAccounts.length || !tokens?.includes(access_token)) {
        multiAccounts.push(accountInfo);
    }

    return multiAccounts;
};

const saveMultiAccounts = (...args) => {
    localStorage.setItem('multi_accounts', JSON.stringify(getMultiAccounts(...args)));
};

const updateMultiAccounts = newUserInfo => {
    const multiAccounts = loadMultiAccounts();

    multiAccounts.forEach(user => {
        if (user.is_active_account) {
            user.user_info = newUserInfo;
        }
    });

    localStorage.setItem('multi_accounts', JSON.stringify(multiAccounts));
};

const clearMultiAccounts = () => {
    localStorage.removeItem('multi_accounts');
};

const loadMultiAccounts = () => {
    const multi_accounts = localStorage.getItem('multi_accounts');

    if (!localStorage.getItem('multi_accounts')) {
        if (localStorage.getItem('apikey')) {
            saveToken(localStorage.getItem('apikey'));
            saveMultiAccounts();
            return getMultiAccounts();
        } else {
            return null;
        }
    } else {
        return JSON.parse(multi_accounts);
    }
};

const removeAccount = (token) => {
    const multi_accounts = JSON.parse(localStorage.getItem('multi_accounts'));

    localStorage.setItem('multi_accounts',
        JSON.stringify(multi_accounts?.filter(account => account.access_token !== token)));
};

const changeActiveUser = token => {
    const multiAccounts = localStorage.getItem('multi_accounts') ? JSON.parse(localStorage.getItem('multi_accounts')) : [];

    if (!!multiAccounts.length) {
        multiAccounts.forEach(user => {
            user.is_active_account = user.access_token === token;

            if (!user.user_full_alias) {
                const user_type_name = getUserTypeName(user.user_info);
                const user_type_alias = getUserTypeAlias(user.user_info);
                const user_full_alias = getUserFullAlias(user.user_info);

                user.user_info = {
                    ...user.user_info,
                    user_type_name,
                    user_type_alias,
                    user_full_alias,
                };
            }
        });
    }

    localStorage.setItem('multi_accounts', JSON.stringify(multiAccounts));
};

const isUserAuthenticated = () => {
    return !!loadApiKey();
};

const getUserTypeName = userInfo => {
    const userType = userInfo?.user_type;
    const alias = userInfo?.alias;

    return userType === 1 ? 'user' :
        userType === 3 && (alias !== 'rkf' || alias === 'rkf-online') ? 'club' :
            userType === 5 || alias === 'rkf' ? 'federation' :
                userType === 4 ? 'kennel' :
                    userType === 101 ? 'admin' : 'nbc';
};

const getUserTypeAlias = userInfo => {
    const userType = userInfo?.user_type;
    const alias = userInfo?.alias;

    return userType === 1 || userType === 101 ? 'user' :
        userType === 3 && (alias !== 'rkf' || alias === 'rkf-online') ? 'club' :
            userType === 5 || alias === 'rkf' ? '' :
                userType === 4 ? 'kennel' : 'nbc';
};

const getUserFullAlias = userInfo => {
    return `/${getUserTypeAlias(userInfo) ? getUserTypeAlias(userInfo) + '/' : getUserTypeAlias(userInfo)}${userInfo?.alias}/`;
};

const authInitialState = {
    isAuthenticated: isUserAuthenticated(),
    is_active_profile: loadIsActiveProfile(),
    personal_office_access: loadPersonalAccess(),
    profile_id: loadProfile(),
    account_type: loadAccountType(),
    user_info: loadUserInfo(),
    helpdesk_api_key: loadHelpdeskApiKey(),
    access_token: loadApiKey(),
    roles: loadRoles(),
    multi_accounts: loadMultiAccounts(),
};

const authReducer = createReducer(authInitialState, {
    [actiontypes.LOGIN_SUCCESS](state, action) {
        const {
            access_token,
            is_active_profile,
            personal_office_access,
            user_info,
            profile_id,
            account_type,
            api_key: helpdesk_api_key,
            roles,
            isFederationClub,
        } = action.data;
        const accountType = loadAccountType();

        saveApiKey(access_token);
        saveUserInfo(user_info);
        saveIsActiveProfile(is_active_profile);
        savePersonalAccess(personal_office_access);
        saveProfile(profile_id);
        if (accountType !== 5 && accountType !== 101) saveAccountType(account_type);
        saveHelpdeskApiKey(helpdesk_api_key);
        saveRoles(roles);
        saveToken(access_token);
        saveMultiAccounts(
            access_token,
            is_active_profile,
            personal_office_access,
            user_info,
            profile_id,
            (accountType === 5 || accountType === 101) && isFederationClub ? accountType : account_type,
            helpdesk_api_key,
            roles
        );

        const multi_accounts = loadMultiAccounts();
        const user_info_update = loadUserInfo();

        return {
            ...state,
            isAuthenticated: true,
            is_active_profile,
            personal_office_access,
            profile_id,
            account_type: (accountType === 5 || accountType === 101) && isFederationClub ? accountType : account_type,
            user_info: user_info_update,
            helpdesk_api_key,
            access_token,
            roles,
            is_active_account: true,
            multi_accounts,
        };
    },
    [actiontypes.LOGOUT](state) {
        clearApiKey();
        clearUserInfo();
        clearIsActiveProfile();
        clearPersonalAccess();
        clearProfile();
        clearAccountType();
        clearHelpdeskApiKey();
        clearRoles();
        clearBanned();
        clearTokens();
        clearMultiAccounts();

        return {
            ...state,
            isAuthenticated: false,
            is_active_profile: false,
            personal_office_access: false,
            profile_id: null,
            account_type: null,
            user_info: null,
            helpdesk_api_key: null,
            roles: null,
            banned: [],
            is_active_account: false,
            multi_accounts: [],
        };
    },
    [actiontypes.UPDATE_USER_INFO](state, action) {
        const user_info = { ...action.data };

        saveUserInfo(user_info);
        updateMultiAccounts(user_info);

        const multi_accounts = loadMultiAccounts();

        return {
            ...state,
            user_info,
            multi_accounts,
        };
    },
    [actiontypes.UPDATE_BANNED](state, action) {
        const banned = action.data;
        saveBanned(banned);

        return {
            ...state,
            banned,
        };
    },
    [actiontypes.LOGOUT_ONE_USER](state) {
        const token = loadApiKey();

        removeAccount(token);
        removeToken(token);

        let multi_accounts = loadMultiAccounts();
        const activeToken = multi_accounts[0].access_token;

        changeActiveUser(activeToken);

        multi_accounts = loadMultiAccounts();

        let activeAccount = {};
        multi_accounts.forEach(account => {
            if (account.is_active_account) {
                activeAccount = account;
            }
        });

        saveApiKey(activeAccount.access_token);
        saveUserInfo(activeAccount.user_info);
        saveIsActiveProfile(activeAccount.is_active_profile);
        savePersonalAccess(activeAccount.personal_office_access);
        saveProfile(activeAccount.profile_id);
        if (activeAccount.accountType !== 5) saveAccountType(activeAccount.account_type);
        saveHelpdeskApiKey(activeAccount.helpdesk_api_key);
        saveRoles(activeAccount.roles);
        saveToken(activeAccount.access_token);

        return {
            ...state,
            ...activeAccount,
            multi_accounts,
        };
    },
    [actiontypes.CHANGE_ACTIVE_USER](state, action) {
        const token = action.data;

        changeActiveUser(token);

        const multi_accounts = loadMultiAccounts();
        let activeAccount = {};

        multi_accounts.forEach(account => {
            if (account.is_active_account) {
                activeAccount = account;
            }
        });

        saveApiKey(activeAccount.access_token);
        saveUserInfo(activeAccount.user_info);
        saveIsActiveProfile(activeAccount.is_active_profile);
        savePersonalAccess(activeAccount.personal_office_access);
        saveProfile(activeAccount.profile_id);
        if (activeAccount.accountType !== 5) saveAccountType(activeAccount.account_type);
        saveHelpdeskApiKey(activeAccount.helpdesk_api_key);
        saveRoles(activeAccount.roles);
        saveToken(activeAccount.access_token);

        return {
            ...state,
            ...activeAccount,
            multi_accounts,
        };
    },
});

export default authReducer;