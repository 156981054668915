import React, { Component } from 'react';
import { string } from 'prop-types';
import Select from 'react-select';
import createFilterOptions from 'react-select-fast-filter-options';
import { connect, getIn } from 'formik';
import { getHeaders } from '../../../../utils/request';
import { isDevEnv } from '../../../../utils';
import MenuList from './ReactSelectMenuList';
import { defaultReactSelectStyles } from './config';

const NoOptionsMessage = () => {
    return ('Нет опций для выбора');
};

const Option = props => (
    <div
        className="react-select__menu-option"
        style={{
            padding: '4px 10px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            cursor: 'pointer',
        }}
        ref={props.innerRef}
        {...props.innerProps}
        title={props.children}
    >
        {props.children}
    </div>
);

// TODO Заменить это дерьмо на что-то современное-актуальное

class FormikReactSelectAsync extends Component {
    static propTypes = {
        optionsEndpoint: string.isRequired,
    };
    static defaultProps = {
        placeholder: 'Выбрать...',
    };
    state = {
        options: [],
    };

    shouldComponentUpdate(nextProps, nextState) {
        const currentValue = getIn(this.props.formik.values, this.props.name);
        const nextValue = getIn(nextProps.formik.values, this.props.name);
        return currentValue !== nextValue || this.state.options !== nextState.options;
    }

    filterOptions = createFilterOptions({
        options:this.state.options,
    });

    loadOptions = () => {
        const options = isDevEnv() ?
            {
                mode: 'cors',
                headers: getHeaders(),
            } :
            {
                headers: getHeaders(),
            };
        fetch(this.props.optionsEndpoint, options)
            .then(response => response.json())
            .then(data => {
                this.processRequest(data.result);
            })
            .catch(error => this.processErrors(error));
    };

    processRequest = result => {
        const options = this.convertDataToOptions(result);

        this.setState({ options });
    };

    processErrors = errors => {
        console.error('processRequestErrors', errors);
    };

    convertDataToOptions = (options) => {
        const { convertDataToOptions } = this.props;
        if (convertDataToOptions) {
            return convertDataToOptions(options);
        }
        return options.map(option => ({
            label: option.name,
            value: option.id,
        }));
    };

    handleChange = (value) => {
        const { name, isMulti } = this.props;
        this.props.formik.setFieldValue(name,
            isMulti
                ? value.map(item => item.value)
                : value.value);
    };

    getValue = () => {
        const { formik, isMulti, name } = this.props;
        const { options } = this.state;
        let value = getIn(formik.values, name);

        if (isMulti && typeof value === 'number') value = [value];
        if (!isMulti && typeof value === 'object') value = value[0];

        if (value && options) {
            return isMulti
                ? options.filter(option => value.indexOf(option.value) >= 0)
                : options.find(option => option.value === value);
        } else {
            return isMulti ? [] : '';
        }
    };

    componentDidMount() {
        this.loadOptions();
    };

    render() {
        const {
            id,
            name,
            className,
            placeholder,
            onBlur,
            disabled,
            clearable,
            defaultValue,
            isMulti,
            closeMenuOnSelect,
        } = this.props;
        const value = this.getValue();
        return (
            <Select
                filterOptions={this.filterOptions}
                components={{ NoOptionsMessage, MenuList, Option }}
                id={id}
                isMulti={isMulti}
                closeMenuOnSelect={closeMenuOnSelect}
                styles={defaultReactSelectStyles.defaultTheme}
                className={className}
                name={name}
                value={value}
                options={this.state.options}
                placeholder={placeholder}
                isDisabled={disabled}
                onChange={this.handleChange}
                onBlur={onBlur}
                clearable={clearable}
                defaultValue={defaultValue}
            />
        );
    }
}


export default connect(FormikReactSelectAsync);