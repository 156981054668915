import React, { memo, useRef, useState } from 'react';
import Select from 'react-select';
import Loading from '../../../../../../../ui/Loading';
import { useDictionary } from '../../../../../../../dictionaries';
import { DICTIONARIES } from '../../../../../../../dictionaries/config';
import { Request } from '../../../../../../../utils/request';
import ActivateClub from './ActivateClub';
import './index.scss';


const ClubRegistration = () => {
    const [clubs, setClubs] = useState(null);
    const [loading, setLoading] = useState(false);
    const [activeClub, setActiveClub] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const clubEl = useRef(null);
    const { dictionary } = useDictionary(DICTIONARIES.cities);
    const selectOptions = [
        { value: 'reset', label: 'Все города' },
        ...dictionary.options,
    ];
    const onClubClick = id => {
        clubEl.current.scrollIntoView();
        setActiveClub(...clubs.filter(club => club.club_id === id));
    };

    const onCityChange = async value => {
        if (value.value && value.value !== 'reset') {
            setLoading(true);
            setSelectedCity(value);
            await Request({
                url: `/api/Club/short_all?LegalCityId=${value.value}&QueryDate=${new Date().toISOString().split('T')[0]}`,
            }, data => {
                setClubs(data);
                setLoading(false);
            }, error => {
                setLoading(false);
                console.error(error.response);
            });
        }

        if (value.value === 'reset') setClubs(null);
    };

    return (
        <>
            <div ref={clubEl} className="registration-page__holder club-registration">
                {activeClub ?
                    <ActivateClub club={activeClub} setActiveClub={setActiveClub} />
                    :
                    <>
                        <div className="club-registration__select-title">Город</div>
                        <Select
                            className="club-registration__select"
                            placeholder="Выберите город"
                            noOptionsMessage={() => 'Город не найден'}
                            options={selectOptions}
                            onChange={onCityChange}
                            defaultValue={selectedCity}
                            styles={{
                                input: styles => ({
                                    ...styles,
                                    width: 200,
                                    height: 41,
                                    paddingTop: '10px',
                                }),
                                container: styles => ({
                                    ...styles,
                                    width: 300,
                                }),
                            }}
                        />
                        {loading && <Loading inline />}
                        {!loading && clubs &&
                            <>
                                {clubs.length ?
                                    <ul className="club-registration__list">
                                        {clubs.map(club =>
                                            <li key={club.club_id} onClick={() => onClubClick(club.club_id)}>
                                                {club.name ? club.name : club.legal_name}
                                            </li>
                                        )}
                                    </ul> :
                                    <div className="club-registration__empty">
                                        <h3 className="club-registration__empty-title">Ничего не найдено</h3>
                                        <div className="club-registration__empty-icon" />
                                    </div>
                                }
                                <p className="club-registration__not-found">
                                    Если Вы не нашли Ваш клуб в списке, обратитесь в свою Федерацию
                                </p>
                            </>
                        }
                    </>
                }
            </div>
            <div className="club-registration__support-links">
                <p>
                    <a
                        href="https://info.rkf.online/cat/340/art/358/registratsiia-kluba-na-platforme-rkfonline/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Инструкция по регистрации клуба на портале RKF.Online
                    </a>
                </p>
            </div>
        </>
    );
};

export default memo(ClubRegistration);
