import React, { memo } from 'react';
import classnames from 'classnames';
import { Field } from 'formik';
import FormInput from '../FormInput';
import Label from './FieldLabel';
import Error from './FieldError';
import FormikDatePicker from './FormikDatePicker';
import FormikAlias from './FormikInputAlias';
import FormikInputCheckbox from './FormikInputCheckbox';
import FormikInputFile from './FormikInputFile';
import FormikInputImage from './FormikInputImage';
import FormikInputMasked from './FormikInputMasked';
import FormikInputNumber from './FormikInputNumber';
import FormikInputUppercase from './FormikInputUppercase';
import FormikTextArea from './FormikTextArea';
import FormikChipList from './FormikChipList';
import FormikContacts from './FormikContacts';
import FormikDownloadFile from './FormikDownloadFile';
import FormikInputTransliterate from './FormikInputTransliterate';
import FormikRadioButton from './FormikRadioButton';
import FormikReactSelect from './FormikReactSelect/FormikReactSelect';
import FormikReactSelectAsync from './FormikReactSelect/FormikReactSelectAsync';
import FormikReactSelectCreatable from './FormikReactSelect/FormikReactSelectCreatable';
import ReactDayPicker from './ReactDayPicker';


const FIELDS = {
    Field: Field,
    formikDatePicker: FormikDatePicker,
    alias: FormikAlias,
    customCheckbox: FormikInputCheckbox,
    file: FormikInputFile,
    image: FormikInputImage,
    masked: FormikInputMasked,
    number: FormikInputNumber,
    uppercase: FormikInputUppercase,
    textarea: FormikTextArea,
    chipList: FormikChipList,
    contacts: FormikContacts,
    fileDownloadable: FormikDownloadFile,
    transliterate: FormikInputTransliterate,
    radioButton: FormikRadioButton,
    reactSelect: FormikReactSelect,
    reactSelectAsync: FormikReactSelectAsync, //Заменить это дерьмо чем-то актуальным
    reactSelectCreatable: FormikReactSelectCreatable,
    reactDayPicker: ReactDayPicker, //Deprecated. Используется только src/pages/ClubEdit/components/LegalInfo/config.js
};

const FormField = ({
    blockIfHasValue = false,
    className,
    description = '',
    disabled,
    type = 'text',
    fieldType,
    isUrl,
    noTouch,
    readOnly,
    style,
    ...fieldProps
}) => {
    const Input = FIELDS.hasOwnProperty(fieldType) ? FIELDS[fieldType] : FIELDS.Field;

    return (
        <FormInput
            style={style}
            name={fieldProps.name}
            className={classnames(
                { [className]: className },
                { [`FormInput--${type}`]: type }
            )}
        >
            {fieldType !== 'customCheckbox' &&
                <Label htmlFor={fieldProps.name} label={fieldProps.label} />
            }
            {isUrl ?
                <Input
                    id={fieldProps.name}
                    placeholder="https://website.com"
                    title="Формат ссылки: https://website.com"
                    pattern="https?://.*"
                    type="url"
                    className="FormInput__input"
                    disabled={disabled || (fieldProps.value && blockIfHasValue)}
                    {...fieldProps}
                />
                :
                <Input
                    id={fieldProps.name}
                    className={!['reactSelect', 'reactSelectCreatable'].includes(fieldType) && 'FormInput__input'}
                    disabled={disabled || (fieldProps.value && blockIfHasValue)}
                    readOnly={readOnly}
                    type={type}
                    {...fieldProps}
                />
            }
            {description &&
                <span className="FormInput__description">
                    {description}
                </span>
            }
            <Error name={fieldProps.name} noTouch={noTouch ? noTouch : null} />
        </FormInput>
    );
};

export default memo(FormField);