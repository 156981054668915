import React from 'react';
import { connect, Field, getIn } from 'formik';


const FormikInputAlias = ({ formik, name, ...props }) => (
    <Field
        className="FormInput__input _alias"
        value={getIn(formik.values, name)}
        onChange={e => formik.setFieldValue(name, e.target.value.replace(/[^A-Za-z0-9_]/g, ''))}
        {...props}
    />
);

export default connect(FormikInputAlias);