import React, { memo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Alert from '../../../../../../../ui/Alert';
import { Form, FormField, SubmitButton } from '../../../../../../Form';
import CustomCheckbox from '../../../../../../../ui/FormComponents/CustomCheckbox';
import personalDataProcessingPolicyDoc from '../../../../../../../pages/PersonalDataProcessingPolicy/Politika_PDn.pdf';
import personalDataProcessingPolicyPDF from '../../../../../../../pages/PersonalDataProcessingPolicy/PPDn.pdf';
import { config, fields } from './config';

import './index.scss';


const IndividualRegistration = ({ errorAlert, setErrorAlert }) => {
    const [successAlert, setSuccessAlert] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const [nameValues, setNameValues] = useState({});

    const history = useHistory();

    const transformValues = values => {
        const newValues = { ...values };

        delete newValues.passwordConfirm;

        return newValues;
    };

    const onSuccess = () => {
        setSuccessAlert(true);

        setTimeout(() => history.push('/auth/login'), 7500);
    };

    const onPrivacyChange = () => {
        setIsChecked(!isChecked);
    };

    const CheckNameValue = (value, name) => {
        let newValue = '';

        if (name === 'last_name' || name === 'first_name' || name === 'second_name') {
            newValue = !!value ? value[0].toUpperCase() + value.slice(1).toLowerCase() : '';
            setNameValues({ ...nameValues, [name]: newValue });
        } else return value;
    };

    return (
        <div className="individual-registration">
            <Form
                {...config}
                className="individual-registration__form"
                transformValues={transformValues}
                onSuccess={onSuccess}
                onError={(e) => {
                    setErrorAlert(true);
                    !!e?.response?.data?.errors?.mail && setErrorText(e.response.data.errors.mail);
                }}
            >
                {fields.map(field =>
                    <FormField
                        key={field.name}
                        {...field}
                        value={nameValues[field.name]}
                        onInput={e => CheckNameValue(e.target.value, field.name)}
                    />)}
                <div className="individual-registration__psw-wrap">
                    <div>
                        <FormField
                            name="password"
                            type={showPassword ? 'text' : 'password'}
                            label="Пароль"
                            placeholder="Введите пароль"
                        />
                        <button
                            className={`individual-registration__psw${showPassword ? ' _show-password' : ''}`}
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                        />
                    </div>
                    <div>
                        <FormField
                            name="passwordConfirm"
                            type={showPasswordConfirm ? 'text' : 'password'}
                            label="Подтверждение пароля"
                            placeholder="Введите подтверждение пароля"
                        />
                        <button
                            className={`individual-registration__psw-confirm${showPasswordConfirm ? ' _show-confirm' : ''}`}
                            type="button"
                            onClick={() => setShowPasswordConfirm(!showPasswordConfirm)}
                        />
                    </div>
                </div>
                <CustomCheckbox
                    id="individual-registration__data-privacy"
                    checked={!!isChecked}
                    onChange={onPrivacyChange}
                />
                <label htmlFor="individual-registration__data-privacy">
                    Я принимаю условия&nbsp;
                    <Link
                        to={personalDataProcessingPolicyDoc}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                            Пользовательского соглашения&nbsp;
                    </Link>
                    и даю свое согласие на обработку моей персональной информации на условиях, определенных&nbsp;
                    <Link
                        to={personalDataProcessingPolicyPDF}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                            Политикой&nbsp;конфиденциальности.
                    </Link>
                </label>
                <SubmitButton
                    className="individual-registration__form-submit btn btn-primary"
                    disabled={!isChecked}
                >
                    Отправить
                </SubmitButton>
            </Form>
            {successAlert &&
                <Alert
                    title="Регистрация прошла успешно!"
                    text="На указанный e-mail отправлено письмо."
                    autoclose={3.5}
                    onOk={() => setSuccessAlert(false)}
                />
            }
            {errorAlert &&
                <Alert
                    title="Произошла ошибка! =("
                    autoclose={3.5}
                    text={!!errorText?.length ? errorText : 'Попробуйте повторить попытку позже, либо воспользуйтесь формой обратной связи.'}
                    onOk={() => {
                        setErrorAlert(false);
                        !!errorText?.length && setErrorText('');
                    }}
                />
            }
        </div>
    );
};

export default memo(IndividualRegistration);