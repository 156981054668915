import { object } from 'yup';
import { mailValidator } from '../../../../utils/mailValidator';


export const PassRecoveryForm = {
    method: 'POST',
    action: '/api/Registration/reset_password_by_mail',
    withLoading: true,
    initialValues: {
        mail: '',
    },
    fields: {
        mail: {
            name: 'mail',
            type: 'email',
            label: 'E-mail',
            placeholder: 'Введите Ваш E-mail',
        },
    },
    validationSchema: object().shape({
        mail: mailValidator().required('Поле не может быть пустым'),
    }),
};