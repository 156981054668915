export const defaultReactSelectStyles = {
    defaultTheme: {
        singleValue: styles => ({
            ...styles,
            color: '#333',

        }),
        multiValue: styles => ({
            ...styles,
            color: '#FFFFFF',
            backgroundColor: '#5097FF',
            textColor: '#FFFFFF',
            borderRadius: '6px',
        }),
        multiValueLabel: styles => ({
            ...styles,
            color: '#FFFFFF',
            fontSize: '14px',
            fontWeight: '400',
            lineHeight: '14px',
        }),
        placeholder: styles => ({
            ...styles,
            color: '#CCCCCC',
            fontSize: 16,
        }),
        control: styles => ({
            ...styles,
            backgroundColor: 'white',
            borderColor: '#EBF0F2',
            borderWidth: 1,
            fontSize: 16,
            lineHeight: '100%',
            paddingTop: 7,
            paddingBottom: 6,
            paddingLeft: 4,
            borderRadius: 6,
        }),
        menuList: styles => ({
            ...styles,
            backgroundColor: 'white',
            color: 'whitesmoke',
            borderColor: '#333',
            borderRadius: 6,
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                        ? '#253C5E'
                        : '#253C5E',
                backgroundColor: isSelected ? '#F6F7F7' : 'transparent',
                padding: '4px 10px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                cursor: isDisabled ? 'not-allowed' : 'default',
            };
        },
    },
};