import React from 'react';
import { getIn, connect } from 'formik';
import './index.scss';


const FormikRadioButton = ({ formik, name, onChange, ...fieldProps }) => (
    <input
        {...fieldProps[0]}
        type="radio"
        className="custom-radio"
        checked={getIn(formik.values, name)}
        onChange={e => {
            formik.setFieldValue(name, e.currentTarget.checked);
            onChange && onChange(e);
        }}
    />
);

export default connect(FormikRadioButton);