import React, { memo } from 'react';
import { connect, getIn } from 'formik';
import DocLink from '../../DocLink';
import FormField from '../Field';

import './index.scss';


const FormFile = ({ formik, name, label, docId, disabled, form, apiEndpoint }) => {

    return (
        <div className="form-file">
            {!disabled && <>
                <FormField name={name} label={label} accept=".pdf, .jpg, .jpeg" fieldType="file" />
                {form && <a download={form.filename} href={form.href}>{form.linkText}</a>}
            </>}
            <DocLink
                apiEndpoint={apiEndpoint}
                docId={docId}
                file={getIn(formik?.values, name)}
                label={label}
                showLabel={disabled}
            />
        </div>
    );
};

export default memo(connect(FormFile));
