import React from 'react';
import { connect, getIn } from 'formik';
import CustomCheckbox from '../../../ui/FormComponents/CustomCheckbox';


const FormikInputCheckbox = ({ formik, name, onChange, checked, ...props }) => (
    <CustomCheckbox
        {...props}
        checked={checked || getIn(formik.values, name)}
        onChange={e => {
            formik.setFieldValue(name, e.currentTarget.checked);
            onChange && onChange(e);
        }}
    />
);

export default connect(FormikInputCheckbox);