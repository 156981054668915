import { object } from 'yup';
import { mailValidator } from '../../../../../utils/mailValidator';

export const loginForm = {
    method: 'POST',
    action: '/api/administration/authentication/login',
    withLoading: true,
    initialValues: {
        mail: '',
    },
    fields: {
        mail: {
            name: 'mail',
            type: 'email',
            label: 'Введите E-mail:',
            placeholder: 'Введите E-mail',
        },
    },
    validationSchema: object().shape({
        mail: mailValidator().required('Укажите E-mail'),
    }),
};