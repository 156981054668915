import React from 'react';
import { connect } from 'formik';
import { FormField } from '../../index';
import transliterate from '../../../../utils/transliterate';
import './index.scss';


const FormikInputTransliterate = ({ formik, name, onChange, ...props }) => (
    <FormField
        {...props}
        name={name}
        onChange={e => {
            formik.handleChange(e);
            formik.setFieldValue(`${name}_lat`, transliterate(e.currentTarget.value));
            onChange && onChange(e);
        }}
    />
);

export default connect(FormikInputTransliterate);