import React, { memo, useEffect, useRef, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import Alert from '../../../ui/Alert';
import Card from '../../../ui/Card';
import PopupModal from '../../../ui/PopupModal';
import useIsMobile from '../../../utils/useIsMobile';
import { Request } from '../../../utils/request';
import { blockContent } from '../../../utils/blockContent';
import { footerNav } from '../../../appConfig';
import { connectAuthUserInfo } from '../../../pages/Login/connectors';
import {
    clubDocumentsMenu,
    clubMenu,
    federationMenu,
    kennelDocumentsMenu,
    kennelMenu, nbcDocumentsMenu,
    nbcMenu, userDocumentsMenu,
    userMenu,
} from './config';
import PresidiumModal from './PresidiumModal';
import MenuList from './MenuList';
import MenuHeader from './MenuHeader';
import './index.scss';

//TODO: После того, как инфа о странице пользователя будет в Редаксе, переписать все запросы на инфу из Редакса

const MenuComponent = ({ isAuthenticated: isAuth, user_info, roles }) => {
    const [currentMenu, setCurrentMenu] = useState([]);
    const [currentPageUserInfo, setCurrentPageUserInfo] = useState(null);
    const [prevAlias, setPrevAlias] = useState('');
    const [prevPage, setPrevPage] = useState('');
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [presidiumAlias, setPresidiumAlias] = useState('');
    const [alertText, setAlertText] = useState('');

    const isMobile = useIsMobile(1080);
    const location = useLocation();
    const userAlias = user_info?.alias;
    const userType = user_info?.user_type;
    const isUserJudge = !!roles?.open_roles?.find(item => item.key_name === 'role_judge');
    const isExhibitionPage = !!useRouteMatch('/exhibitions/:id');
    const exhibitionId = useRouteMatch('/exhibitions/:id')?.params.id;
    const menuButton = useRef();

    useEffect(() => {
        getMenu();
    }, [location, isMobile]);

    useEffect(() => {
        if (!isMenuOpen) blockContent(false);
    }, [isMenuOpen]);

    useEffect(() => {
        if (isMenuOpen && !isMobile) {
            setIsMenuOpen(false);
            setCurrentPageUserInfo(null);
        }
    }, [isMobile, isMenuOpen]);

    const getCurrentPageUserInfo = async (alias, url) => {
        if (alias === userAlias) {
            setCurrentPageUserInfo({ ...user_info, open_roles: roles?.open_roles });
        } else if (url) {
            await Request(
                { url: url },
                data => {
                    if (data) {
                        setCurrentPageUserInfo(data);
                    }
                },
                error => {
                    console.error(error.response);
                });
        }
    };

    const getFederationMenu = async alias => {
        let requisitesLink = '';
        let feesLink = '';

        const data = await Request({
            url: `/api/federation/federation_documents?Alias=${alias}`,
        });

        if (data?.length && data[0].documents?.length) {
            requisitesLink = await Request({
                url: `/api/document/document/public?id=${data[0].documents.filter(doc => doc.document_type_id === 1)[0]?.document_id}`,
            });

            feesLink = await Request({
                url: `/api/document/document/public?id=${data[0].documents.filter(doc => doc.document_type_id === 2)[0]?.document_id}`,
            });
        }

        setCurrentMenu(federationMenu(alias, requisitesLink, feesLink));
    };

    const getClubMenu = alias => {
        setCurrentMenu(clubMenu(alias, alias === userAlias));
    };

    const getKennelMenu = alias => {
        setCurrentMenu(kennelMenu(alias, alias === userAlias));
    };

    const getNBCMenu = async alias => {
        //слать запрос, чтобы в ссылку мероприятий добавить id пород - это не есть хорошо
        await Request(
            { url: `/api/NationalBreedClub/full?alias=${alias}` },
            data => {
                const strOfBreeds = data.breeds?.map(obj => `BreedIds=${obj.breed_id}`).join('&');

                setCurrentMenu(nbcMenu(alias, alias === userAlias, strOfBreeds));
            },
            error => {
                console.error(error.response);
            });
    };

    const getUserMenu = async alias => {
        if (alias === userAlias) {
            setCurrentMenu(userMenu(userAlias, true, isUserJudge));
        } else {
            //слать запрос из-за одного слова в меню - это полный бред
            await Request(
                { url: `/api/owners/owner/public/${alias}` },
                data => {
                    const isJudge = !!data?.open_roles?.find(item => item.key_name === 'role_judge');

                    setCurrentMenu(userMenu(alias, false, isJudge));
                },
                error => {
                    console.error(error.response);
                });
        }
    };

    const getExhibitionMenu = async () => {
        await Request(
            { url: `/api/exhibitions/exhibition/${exhibitionId}` },
            data => {
                const clubAlias = data?.club_information?.club_alias;

                if (clubAlias) {
                    if (
                        clubAlias === 'rkf' ||
                        clubAlias === 'rfls' ||
                        clubAlias === 'rfss' ||
                        clubAlias === 'oankoo' ||
                        clubAlias === 'rfos'
                    ) {
                        getFederationMenu(clubAlias);
                    } else {
                        getClubMenu(clubAlias);
                    }

                    if (isMobile) {
                        getCurrentPageUserInfo(clubAlias, `/api/Club/public/${clubAlias}`);
                    }
                }
            },
            error => {
                console.error(error.response);
            });
    };

    const getDocumentsMenu = () => {
        if (userType === 3 || userType === 5) {
            setCurrentMenu(clubDocumentsMenu(userAlias));
        } else if (userType === 4) {
            setCurrentMenu(kennelDocumentsMenu(userAlias));
        } else if (userType === 7) {
            setCurrentMenu(nbcDocumentsMenu(userAlias));
        } else if (userType === 1) {
            setCurrentMenu(userDocumentsMenu(userAlias, isUserJudge));
        }
    };

    const getMyPageMenu = async () => {
        if (userType === 1) {
            await getUserMenu(userAlias);
        } else if (userType === 3 && userAlias !== 'rkf') {
            getClubMenu(userAlias);
        } else if (userType === 4) {
            getKennelMenu(userAlias);
        } else if (userType === 5 || userAlias === 'rkf') {
            getFederationMenu(userAlias);
        } else if (userType === 7) {
            await getNBCMenu(userAlias);
        }
    };

    const getMenu = async () => {
        const pathArray = location.pathname.replace('/', '').split('/');
        let pageName = '';
        let pageAlias = '';
        let isDifferentMenu = true;

        switch (true) {
        //Меню ЛК
        case pathArray.includes('documents') || pathArray.includes('bank-details') || pathArray.includes('base-search'):
            pageName = 'documents';
            pageAlias = userAlias;
            isDifferentMenu = pageAlias && (pageAlias !== prevAlias || pageName !== prevPage);

            if (isDifferentMenu) {
                setPrevPage(pageName);
                setPrevAlias(pageAlias);
                getDocumentsMenu();
            }

            if (isMobile && (!currentPageUserInfo || isDifferentMenu)) {
                await getCurrentPageUserInfo(pageAlias);
            }
            break;
            //Меню клуба
        case pathArray.includes('club'):
            pageName = 'club';
            pageAlias = pathArray[pathArray.indexOf('club') + 1];
            isDifferentMenu = pageAlias !== prevAlias || pageName !== prevPage;

            if (isDifferentMenu) {
                setPrevPage(pageName);
                setPrevAlias(pageAlias);
                getClubMenu(pageAlias, );
            }

            if (isMobile && (!currentPageUserInfo || isDifferentMenu)) {
                await getCurrentPageUserInfo(pageAlias, `/api/Club/public/${pageAlias}`);
            }
            break;
        case pathArray.includes('client'):
            pageName = 'clubEdit';
            pageAlias = userAlias;
            isDifferentMenu = pageAlias !== prevAlias || pageName !== prevPage;

            if (isDifferentMenu) {
                setPrevPage(pageName);
                setPrevAlias(pageAlias);
                await getMyPageMenu();
            }

            if (isMobile && (!currentPageUserInfo || isDifferentMenu)) {
                await getCurrentPageUserInfo(pageAlias);
            }
            break;
            //Меню питомника
        case pathArray.includes('kennel'):
            pageName = 'kennel';
            pageAlias = pathArray[pathArray.indexOf('kennel') + 1];
            isDifferentMenu = pageAlias !== prevAlias || pageName !== prevPage;

            if (isDifferentMenu) {
                setPrevPage(pageName);
                setPrevAlias(pageAlias);
                getKennelMenu(pageAlias);
            }

            if (isMobile && (!currentPageUserInfo || isDifferentMenu)) {
                await getCurrentPageUserInfo(pageAlias, `/api/nurseries/nursery/public/${pageAlias}`);
            }
            break;
            //Меню НКП
        case pathArray.includes('nbc'):
            pageName = 'nbc';
            pageAlias = pathArray[pathArray.indexOf('nbc') + 1];
            isDifferentMenu = pageAlias !== prevAlias || pageName !== prevPage;

            if (isDifferentMenu) {
                setPrevPage(pageName);
                setPrevAlias(pageAlias);
                await getNBCMenu(pageAlias);
            }

            if (isMobile && (!currentPageUserInfo || isDifferentMenu)) {
                await getCurrentPageUserInfo(pageAlias, `/api/NationalBreedClub/full?alias=${pageAlias}`);
            }
            break;
            //Меню Физика
        case pathArray.includes('user'):
            pageName = 'user';
            pageAlias = pathArray[pathArray.indexOf('user') + 1];
            isDifferentMenu = pageAlias !== prevAlias || pageName !== prevPage;

            if (isDifferentMenu) {
                setPrevPage(pageName);
                setPrevAlias(pageAlias);
                await getUserMenu(pageAlias);
            }

            if (isMobile && (!currentPageUserInfo || isDifferentMenu)) {
                await getCurrentPageUserInfo(pageAlias, `/api/owners/owner/public/${pageAlias}`);
            }
            break;
            //Меню федерации
        case pathArray.includes('rkf') || pathArray.includes('rfls') || pathArray.includes('rfss') || pathArray.includes('oankoo') || pathArray.includes('rfos'):
            pageName = 'federation';
            pageAlias = pathArray[0];
            isDifferentMenu = pageAlias !== prevAlias || pageName !== prevPage;

            if (isDifferentMenu) {
                setPrevPage(pageName);
                setPrevAlias(pageAlias);
                getFederationMenu(pageAlias);
            }

            if (isMobile && (!currentPageUserInfo || isDifferentMenu)) {
                await getCurrentPageUserInfo(pageAlias, `/api/Club/public/${pageAlias}`);
            }
            break;
            //Меню на странице выставки (клуба/федерации)
        case isExhibitionPage:
            pageName = 'exhibition';
            setPrevPage(pageName);
            setPrevAlias(pageAlias);

            await getExhibitionMenu();
            break;
            //Меню залогиненого пользователя на других страницах
        default:
            pageName = 'other';
            pageAlias = userAlias || '';
            isDifferentMenu = pageAlias !== prevAlias || pageName !== prevPage;

            if (isDifferentMenu) {
                setPrevPage(pageName);
                setPrevAlias(pageAlias);

                if (pageAlias) {
                    await getMyPageMenu();
                } else {
                    setCurrentMenu([]);
                }
            }

            if (isMobile && (!currentPageUserInfo || (pageAlias && isDifferentMenu))) {
                await getCurrentPageUserInfo(pageAlias);
            }
            break;
        }
    };

    const clickOnPresidium = alias => {
        setPresidiumAlias(alias);
        setIsMenuOpen(false);
        blockContent(true);
    };

    const closePresidiumModal = () => {
        setPresidiumAlias('');
        blockContent(false);
    };

    const clickOnDisabledLink = needAuth => {
        setAlertText(needAuth ?
            'Для перехода в данный раздел Вам необходимо пройти авторизацию' :
            'Раздел находится в разработке'
        );
        setIsMenuOpen(false);
        blockContent(false);
    };

    const clickOnMenuButton = () => {
        setIsMenuOpen(!isMenuOpen);
        blockContent(!isMenuOpen);
    };

    const closeMenu = e => {
        if (!menuButton.current?.contains(e.target)) {
            setIsMenuOpen(false);
            blockContent(false);
        }
    };

    return (
        <>
            {!!currentMenu.length && (!isMobile ?
                <Card className="menu-wrap">
                    <MenuList
                        menu={currentMenu}
                        setIsMenuOpen={setIsMenuOpen}
                        clickOnPresidium={clickOnPresidium}
                        clickOnDisabledLink={clickOnDisabledLink}
                    />
                </Card>
                :
                <>
                    <button
                        className={`menu-component__button${isMenuOpen ? ' _open' : ''}`}
                        onClick={clickOnMenuButton}
                        ref={menuButton}
                    >
                        {footerNav[4].image}
                        <span className="menu-component__button-text">{footerNav[4].title}</span>
                    </button>
                    <PopupModal
                        showModal={isMenuOpen}
                        handleClose={closeMenu}
                        bottomStyle
                    >
                        <CSSTransition
                            classNames="menu-component__transition"
                            in={isMenuOpen}
                            timeout={400}
                            mountOnEnter
                            unmountOnExit
                        >
                            <div className="menu-component__inner">
                                <MenuHeader
                                    currentPageUserInfo={currentPageUserInfo}
                                    setIsMenuOpen={setIsMenuOpen}
                                />
                                <MenuList
                                    menu={currentMenu}
                                    setIsMenuOpen={setIsMenuOpen}
                                    clickOnPresidium={clickOnPresidium}
                                    clickOnDisabledLink={clickOnDisabledLink}
                                />
                            </div>
                        </CSSTransition>
                    </PopupModal>
                </>
            )}
            {presidiumAlias &&
                <PresidiumModal
                    alias={presidiumAlias}
                    onClose={closePresidiumModal}
                />
            }
            {alertText &&
                <Alert
                    title="Внимание!"
                    text={alertText}
                    autoclose={1.5}
                    onOk={() => setAlertText('')}
                />
            }
        </>
    );
};

export default memo(connectAuthUserInfo(MenuComponent));