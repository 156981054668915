import React, { memo } from 'react';
import { DEFAULT_IMG } from '../../appConfig';
import './index.scss';


const CustomAvatar = ({
    size = 110,
    avatarUrl = DEFAULT_IMG.clubAvatar,
    className,
}) => (
    <div
        className={`custom-avatar${className ? ' ' + className : ''}`}
        style={{
            backgroundImage: `url(${avatarUrl})`,
            width: size + 'px',
            minWidth: size + 'px',
            height: size + 'px',
            minHeight: size + 'px',
        }}
    />
);

export default memo(CustomAvatar);